/*-------------------------------------
    
	Author: Webstrot 
	Copyright © 2018
	
---------------------------------------
    
    CSS INDEX
    ===================
	
    1. BODY
	2. CUSTOM CSS / A
	3. PRELOADER
	4. NAVIGATION
	5. SLIDER
	6. UPCOMING MOVIES
	7. VIDEO SECTION 
	8. WEB SERIES
	9. TOP MOVIES IN THEATRES
	10. LATEST NEWS
	11. FEATURED EVENTS
	12. VIDEO & PHOTOS
	13. OUR PATNER’S
	14. FOOTER

---------------------------------------*/
/*--------------
1.Theme default CSS
--------------------------*/
body, html {
	font-family: 'Lato', sans-serif;
	font-size: 16px;
	line-height: 23px;
	color: #707070;
	padding-right:0 !important;
	overflow-x:hidden;
}
body::-webkit-scrollbar-track{
	-webkit-boxShadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #ffffff;
}
body::-webkit-scrollbar{
	width: 4px;
	background-color: #ffffff;
}

body::-webkit-scrollbar-thumb{
	background-color: #000000;
}
a {
	color: #797979;
	text-decoration: none;
}
a:hover, a:focus {
	color: #707070;
	text-decoration: none;
	outline: none;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Poppins', sans-serif;
	line-height: 1.1;
	font-weight:400;
	color: #222222;
	margin:0;
	padding:0;
}
p{
	margin:0;
}
input, select, button, textarea {
	outline: none;
}
input:focus, select:focus, button:focus, textarea:focus {
	outline: none;
	box-shadow:none;
}
ul{
	list-style:none;
	margin:0;
	padding:0;
}

/*-- Preloader css start --*/
#preloader {
	    background-color: #ebf2f5;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 2147483647;
	width: 100%;
	height: 100%;
}
#status {
    background-position: center center;
    background-repeat: no-repeat;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
}
#status img {
}
#return-to-top {
    position: fixed;
    bottom: 37px;
    right: 50px;
    background: #ff4444;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    display: none;
	    -moz-transform: rotate(31deg);
    -webkit-transform: rotate(31deg);
    -o-transform: rotate(31deg);
    -ms-transform: rotate(31deg);
    transform: rotate(31deg);
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
	padding-left:15px;
	z-index:1000;
}
#return-to-top i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 1px;
    top: 9px;
    font-size: 30px;
	
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top i:before{
	margin-left:0;
}
/*---------------------colorpicker css start---------------------*/
#style-switcher {
	background: none repeat scroll 0 0 #fff;
	-webkit-box-shadow: 2px 2px 3px rgba(0,0,0,.3);
	-moz-box-shadow: 2px 2px 3px rgba(0,0,0,.3);
	-ms-box-shadow: 2px 2px 3px rgba(0,0,0,.3);
	-o-box-shadow: 2px 2px 3px rgba(0,0,0,.3);
	box-shadow: 2px 2px 3px rgba(0,0,0,.3);
	border: 1px solid #ECECEC;
	left: 0px;
	position: fixed;
	top: 20%;
	width: 160px;
	z-index: 999999999;
}
#style-switcher div {
	padding: 5px 9px;
	float: left;
}
ul.colors {
	list-style: none outside none;
	margin: 0 0 10px;
	overflow: hidden;
	padding: 0;
}
ul.colors li {
	float: left;
	margin: 2px;
}
ul.colors li #color {
	background: #ff4444;
}
ul.colors li #color2 {
	background: #38cfea;
}
ul.colors li #color3 {
	background: #ff9344;
}
ul.colors li #color4 {
	background: #614aee;
}
ul.colors li #color5 {
	background: #5ba536;
}
ul.colors li #style {
	background: #22be9f;
}
ul.colors li p {
	cursor: pointer;
	display: block;
	height: 39px;
	width: 39px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	font-size: 0px;
	transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transition: all 0.2s;
	-moz-transition: all 0.2s;
	-ms-transition: all 0.2s;
	-o-transition: all 0.2s;
}
ul.colors li:hover p {
	transform: scale(1.1);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transition: all 0.2s;
	-moz-transition: all 0.2s;
	-ms-transition: all 0.2s;
	-o-transition: all 0.2s;
}
#style-switcher .bottom {
	background: none repeat scroll 0 0 #FFFFFF;
	color: #252525;
	padding: 0;
}
#style-switcher .bottom a.settings {
	background: none repeat scroll 0 0 #fff;
	-webkit-box-shadow: 2px 2px 3px rgba(0,0,0,.3);
	-moz-box-shadow: 2px 2px 3px rgba(0,0,0,.3);
	-ms-box-shadow: 2px 2px 3px rgba(0,0,0,.3);
	-o-box-shadow: 2px 2px 3px rgba(0,0,0,.3);
	box-shadow: 2px 2px 3px rgba(0,0,0,.3);
	border: 1px solid #ECECEC;
	border-left: none;
	display: block;
	height: 41px;
	position: absolute;
	right: -40px;
	top: -1px;
	width: 40px;
	padding: 6px;
	font-family: sosa;
	line-height: 40px;
	color: #000;
}
#style-switcher .bottom a.settings i {
	font-size: 30px;
	color: #000;
	animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
#style-switcher > div > h3 {
	font-size: 14px;
	text-transform: capitalize;
	font-weight: 400;
	text-align: left;
	color: #000000 !important;
	padding-top: 10px;
    padding-bottom: 10px;
}
#style-switcher > div > select {
	width: 90%;
}	
/*-- prs navigation Start --*/
.prs_navigation_main_wrapper{
	float:left;
	width:100%;
	background:#ff4444;
	position:relative;
	z-index:10000;
}
.menu_fixed{
	position:fixed;
	background:#ff4444;
	-webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow:  0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow:  0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow:  0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow:  0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	z-index:1000;
	left:0;
	right:0;
}
.prs_navi_left_main_wrapper{
	float:left;
	width:55%;
}
.prs_navi_right_main_wrapper{
	float:left;
	width:45%;
	padding-top: 25px;
}
.prs_logo_main_wrapper{
	float:left;
	padding-top: 30px;
    padding-bottom: 30px;
	position: relative;
    z-index: 1;
}
.prs_menu_main_wrapper{
	float:left;
	padding-left: 30px;
}
#sticktop {
	width: 100%;
	left: 0;
}
.sticky-wrapper.is-sticky #sticktop {
	-moz-boxShadow: 0px 1px 2px rgba(0, 0, 0, .15);
	-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, .15);
	-o-box-shadow: 0px 1px 2px rgba(0, 0, 0, .15);
	box-shadow: 0px 1px 2px rgba(0, 0, 0, .15);
}
.dl-menuwrapper button { background: #dd3333;    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px; 
	margin-right:10px;}
.dl-menuwrapper button:hover, .dl-menuwrapper button.dl-active, .dl-menuwrapper ul { background: #dd3333; }
.dl-menuwrapper {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}
.fixed-navigation.dl-menuwrapper {
	position: fixed;
	right: -80px;
	top: 40px;
	z-index: 9999;
}
.dl-trigger-wrapper { position: relative; }
.dl-menuwrapper button { float: right; }
ul.navbar-nav {
	float: right;
	min-height: 40px;
}
.navbar { margin-bottom: 0; }
.navbar-default {
	z-index: 999999;
	border-radius: 0;
}
.navbar-default > .container {
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
	color: #303030;
	background: none;
}
.navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
	font-size: 16px;
	color: #303030;
	font-weight: 400;
	text-transform: capitalize;
	text-align: center;
}
.navbar-default {
	background: transparent;
	border: none;
}
.navbar-nav > li {
	margin: 0;
	padding: 34px 22px 30px 22px;
	position: relative;
}
.navbar-nav > li.active {
	background: #2bb7ff;
	color: #fff !important;
	position: relative;
}
.navbar-nav > li > a {
	padding: 0;
	padding-top: 5px;
	padding-bottom: 0px;
	padding-left: 3px;
	padding-right: 3px;
	outline: none !important;
	overflow: hidden;
	cursor: pointer;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus { color: #fff; }
.navbar-default .container { position: relative; }
.navbar-default .navbar-toggle {
	color: #fff;
	border-color: #fff;
	top: -2px;
	right: 10px;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus { background: none; }
.navbar-default ul.dropdown-menu {
	background: #2bb7ff;
	min-width: 140px;
	top: 100%;
	padding: 0;
	border-radius: 0;
	opacity: 0.95;
}
.navbar-default ul.dropdown-menu li a {
	color: #fff;
	padding: 10px 16px;
	outline: none;
	border-bottom: 1px solid #339bd7;
}
.dropdown i {
	padding-left: 2px;
	font-size: 12px;
}
.navbar-default .navbar-brand {
	line-height: 40px;
	padding: 0;
}
.navbar > .container .navbar-brand {
	margin-left: 0;
	line-height: 90px;
	float: none;
	display:block;
}
.navbar-default .navbar-brand:hover { color: #2d3538; }
a.navbar-brand img { padding-right: 15px; }
.navbar-nav > li:hover ul.dropdown-menu { display: block; }
.xv-menuwrapper { float: right; }
.xv-menuwrapper .dl-trigger { display: none; }
.xv-menuwrapper .dl-menu {
	position: static;
	display: block;
	padding: 0;
	text-align: left;
	float: none;
}
.xv-menuwrapper .dl-menu > li {
	list-style: none;
	display: block;
	float: left;
	height: 100%;
	line-height: 100%;
	position: relative;
}
.xv-menuwrapper .dl-menu > li.active > a { color: #fff; }
.xv-menuwrapper .dl-menu > li > a {
	display: block;
	font-size: 14px;
	color: #ffffff;
	font-weight:500;
	position: relative;
	padding: 42px 10px;
	text-transform: uppercase;
}
.xv-menuwrapper .dl-menu > li.active:after, .xv-menuwrapper .dl-menu > li.megamenu.active >a:after {
	font-family: 'FontAwesome';
	content: "\f0d8";
	width: 100%;
	text-align: center;
	height: 7px;
	position: absolute;
	left: 0;
	bottom: 4px;
	color: #fff;
	font-size: 20px;
}
.xv-menuwrapper .dl-menu > li.megamenu.active > a:after { bottom: 42px; }
.xv-menuwrapper .dl-menu > li.megamenu.active:after { display: none; }
.xv-menuwrapper li.parent { position: relative; }
.xv-menuwrapper li.parent.megamenu { position: static; }
.xv-menuwrapper li.parent.megamenu > .lg-submenu {
	display: block;
	width: 100%;
	text-align: justify;
	left: 0;
	right: 0;
	padding: 15px 15px;
}
.xv-menuwrapper li.parent.megamenu > .lg-submenu:before {
    content: '';
    border-right: 13px solid transparent;
    border-bottom: 13px solid #fff;
    border-left: 13px solid transparent;
    position: absolute;
    left: 16%;
    top: -13px;
}
.xv-menuwrapper li.parent.megamenu > .prs_navi_video_wrapper:before {
    content: '';
    border-right: 13px solid transparent;
    border-bottom: 13px solid #fff;
    border-left: 13px solid transparent;
    position: absolute;
    left: 24% !important;
    top: -13px;
}
.xv-menuwrapper li.parent.megamenu.small-menu > .lg-submenu { width: 60%; }
.xv-menuwrapper li.parent.megamenu.medium > .lg-submenu img {
	border: 1px solid #f2f2f2;
	padding: 8px;
}
.xv-menuwrapper li.parent.megamenu.wide > .lg-submenu { width: 100%; }
.xv-menuwrapper li.parent.megamenu > .lg-submenu::after {
	width: 100%;
	display: inline-block;
	content: ".";
	visibility: hidden
}
.xv-menuwrapper li.parent.megamenu > .lg-submenu > li {
	float:left;
	width:20%;
	text-align: left;
	vertical-align: top;
}
.xv-menuwrapper li.parent.megamenu > .lg-submenu > li > a {
	font-weight: 800;
	color: #13212d;
	display: block;
	font-size:14px;
	text-transform:uppercase;
}
.xv-menuwrapper li.parent.megamenu > .lg-submenu figure {
	overflow: hidden;
	width: 330px;
	max-height: 340px;
}
.xv-menuwrapper li.parent.megamenu > .lg-submenu figure img {
	width: 100%;
	height: auto;
}
.xv-menuwrapper .dl-menu > li.parent > ul, .xv-menuwrapper .dl-menu > li.parent > ul > li.parent > ul {
	padding: 0;
	position: absolute;
	left: 0;
	top: 100%;
	min-width: 215px;
	background: #fff;
	visibility: hidden;
	opacity: 0;
	margin-top:30px;
	z-index:10;
	-moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.xv-menuwrapper .dl-menu > li.parent > ul:after{
	content: '';
    border-right: 13px solid transparent;
    border-bottom: 13px solid #fff;
    border-left: 13px solid transparent;
    position: absolute;
    left: 20px;
    top: -13px;
}

.xv-menuwrapper .dl-menu > li.parent > ul > li.parent > ul { margin-top: -1px; }
.xv-menuwrapper .dl-menu > li.parent:last-child > ul {
	left: auto;
	right: 0;
}
.xv-menuwrapper .dl-menu > li.parent:hover > ul, .xv-menuwrapper .dl-menu > li.parent > ul > li.parent:hover > ul {
	visibility: visible;
	opacity: 1;
	z-index:10;
	margin-top:0;
	    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	    -webkit-border-top-left-radius: 0;
    -moz-border-top-left-radius: 0;
    border-top-left-radius: 0;
	    -webkit-border-top-right-radius: 0;
    -moz-border-top-right-radius: 0;
    border-top-right-radius: 0;
	transform: translateY(0);
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-o-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.xv-menuwrapper .dl-menu > li.parent > ul > li, .xv-menuwrapper .dl-menu > li.parent > ul > li.parent ul li {
	display: block;
	list-style: none;
	border-top: 1px solid #000;
	border-top: 1px solid rgba(0,0,0,0.2);
	padding: 10px;
}
.xv-menuwrapper .dl-menu > li.parent.megamenu > ul > li, .xv-menuwrapper .dl-menu > li.parent.megamenu > ul > li.parent ul li { border-top: none; }
.xv-menuwrapper .dl-menu > li.parent.megamenu li { list-style: none; }
.xv-menuwrapper .dl-menu > li.parent.megamenu li:last-child{
	overflow:hidden;
}
.xv-menuwrapper .dl-menu > li.parent.megamenu li a {
	color: #13212d;
	line-height: 30px;
	margin-bottom:15px;
}
.xv-menuwrapper .dl-menu > li.parent.megamenu li a:hover{
	color:#ff4444;
}
.xv-menuwrapper .dl-menu > li.parent > ul > li > a, .xv-menuwrapper .dl-menu > li.parent > ul > li.parent ul li a {
	display: block;
	line-height: 30px;
	color: #13212d;
	text-transform: capitalize;
}
.xv-menuwrapper .dl-menu > li.parent.megamenu > ul > li:hover{
	background:none;
}
.xv-menuwrapper .dl-menu > li.parent > ul > li.parent:after {
	position: absolute;
	top: 0;
	right: 10px;
	line-height: 50px;
	font-family: 'FontAwesome';
	speak-as: none;
	-webkit-font-smoothing: antialiased;
	content: "\f0da";
	color: #13212d;
}
.xv-menuwrapper .dl-menu > li.parent > ul > li.parent ul {
	position: absolute;
	left: 100%;
	top: 0;
}
.xv-menuwrapper .dl-menu > li.parent:last-child > ul > li.parent > ul {
	left: auto;
	right: 100%;
}
.xv-menuwrapper button:after { display: none; }
.xv-menuwrapper .dl-back { display: none !important; }
.megamenu i{
	padding-right:15px;
	color:#939a9e;
}
.dl-trigger img{
	position: absolute;
    left: 0;
    right: 0;
    margin: 0px auto;
    top: 34%;
}
.ar_left a:after{
	display:none;
}
/*--- navigation slider ------*/
.prs_navi_slider_wraper{
	height:350px;
}
.prs_navi_slider_wraper  .owl-theme .owl-dots{
	display:none;
}
.prs_navi_slider_wraper .owl-theme .owl-nav {
    position: absolute;
    top: 30%;
    z-index: 1000;
    display: block;
	left:0;
	right:0;
	margin:0px auto;
	display:none;
}
.prs_navi_slider_wraper .owl-theme .owl-nav .owl-prev{
    margin: 0;
    padding: 0;
    left: 20px;
	font-size: 26px;
	top:0;
	color:#ffffff;
	width:50px;
	height:50px;
	line-height:45px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.prs_navi_slider_wraper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
    right: 20px;
	top:-5px;
    position: absolute;
	color:#ffffff;
	width:50px;
	height:50px;
	line-height:40px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_navi_slider_wraper  .owl-theme .owl-nav .owl-next i:before{
	color:#ffffff;
	margin-left:10px;
}
.prs_navi_slider_wraper .owl-theme .owl-nav .owl-prev i:before {
	margin-left:15px;
	color:#ffffff;
}
.lg-submenu li i{
	color:#ff4444;
}
.prs_navi_video_wrapper li img{
	width:100%;
}
.prs_navi_video_wrapper li{
	width:16.6% !important;
}
.prs_video_navi_img_main_wrapper{
	position:relative;
}
.prs_video_navi_overlay_wrapper:after{
	content:'';
	position:absolute;
	top:200px;
	bottom:200px;
	right:200px;
	left:200px;
	background:rgba(0,0,0,0.8);
	opacity:0;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_video_navi_img_main_wrapper:hover .prs_video_navi_overlay_wrapper:after{
	content:'';
	position:absolute;
	top:0;
	bottom:0;
	right:0;
	left:0;
	background:rgba(0,0,0,0.8);
	opacity:1;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_video_navi_overlay_wrapper a{
	float:left;
	width:40px;
	height:40px;
	line-height:40px;
	text-align:center;
	background:#ff4444;
	position:absolute;
	left:0;
	right:0;
	margin:0px auto;
	top:42%;
	font-size:14px;
	-webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
	z-index:10;
	opacity:0;
	    animation-duration: 1s;
    animation-delay: 100ms;
    animation-iteration-count: infinite;
    animation-name: pulse;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_video_navi_img_main_wrapper:hover .prs_video_navi_overlay_wrapper a{
	opacity:1;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_video_navi_overlay_wrapper a i:before{
	margin-left: 14px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    position: relative;
    top: 6px;
}
.xv-menuwrapper .dl-menu > li.parent > ul > li:hover a, .xv-menuwrapper .dl-menu > li.parent > ul > li.parent ul li:hover a{
	color:#ff4444;
}
.xv-menuwrapper .dl-menu > li.megamenu > ul > li:hover a{
	color:#000000;
}
.xv-menuwrapper .dl-menu > li.parent > ul > li.parent:hover:after{
	color:#ff4444;
}

/*--- navigation slider ------*/
/*=============================================
6-Top level nav
=============================================*/
.top-level-nav {
	padding: 0;
	text-align: left;
}
.top-level-nav.text-right { text-align: right; }
.top-level-nav li { display: inline-block; }
.top-level-nav li + li:before {
	content: "|";
	padding: 0 10px;
	color: #fff;
}
.top-level-nav li a { color: #fff; }
.slect-lang a { opacity: 0.5; }
.slect-lang a.active {
	opacity: 1;
	margin-right: 1px;
}
.slect-lang a.active img, .slect-lang a:hover img {
	border: 1px solid #13212d;
	margin: -1px;
}
.custome-select {
	width: 85px;
	max-width: 100%;
	position: relative;
	height: 25px;
	line-height: 25px;
	text-align: left;
	display: inline-block;
	cursor: pointer;
}
.custome-select.style2 {
	width: 100%;
	border: 1px solid #b1b6ba;
	padding: 5px;
	height: 35px;
	background: #fff;
	margin-bottom: 10px;
}
.custome-select.style2.normal { max-width: 300px; }
.custome-select span {
	width: 100%;
	color: #fff;
	padding-left: 5px;
	font-size: 15px;
	display: block;
	font-weight: 400;
}
.custome-select.style2 span { color: #000; }
.custome-select span b {
	float: right;
	margin-top: 5px;
	margin-right: 5px;
}
.custome-select select {
	opacity: 0;
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	height: 25px;
}
.cart-look {
	position:relative;
	text-align: right;
	padding-top: 2px;
	display: inline-block;
}
.cart-look.centered { text-align: center; }
.cart-look.left-aligned { text-align: left; }
.cart-look a {
	color: #415273;
	text-decoration: none;
	font-size: 22px;
	top: 4px;
	position: relative;
}
.cart-look a .cart-item-count {
	position: absolute;
	right: 0;
	top: -10px;
	height: 15px;
	width: 15px;
	color: #fff;
	font-size: 12px;
	text-align: center;
	line-height: 18px;
	font-family: 'Lato', sans-serif;
}
.cart-look path{
	fill:#fff;
}
#sticktop-sticky-wrapper{
	height:0;
}
.blog-area .product-heading .con .nice-select, .pro_shop_select .nice-select {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  line-height: 50px;
  padding: 0 20px;
  height: 50px;
  border-radius: 0;
  margin-top: 0;
  color: rgba(255, 255, 255, 0.5);
}
.blog-area .product-heading .con .nice-select:after, .pro_shop_select .nice-select:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
.blog-area .product-heading .con .nice-select ul, .pro_shop_select .nice-select ul {
  border-radius: 0;
  width: 100%;
  z-index: 9999;
}
.blog-area .product-heading .con .nice-select ul li {
  display: block;
  width: 100%;
  color: #333;
}
.blog-area .product-heading .con .nice-select ul li:hover {
  background: #cf6161;
  color: #fff;
}
.pro_shop_select .nice-select ul li{
	color:#000000 !important;
}
.pro_shop_select .nice-select ul li:hover{
	background: #cf6161;
  color: #fff !important;
}
.product-heading .pro_shop_select .nice-select ul li:hover {
    background: #cf6161;
    color: #fff;
}
.nice-select{
	height:50px;
	border:0;
	border-radius:0;
	background:#f6f6f6;
	line-height:50px;
	-webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.product-heading .con input{
	float:left;
	width:calc(300px - 50px);
	height:50px;
	background:#ffffff;
	padding-left:20px;
	padding-right:20px;
	border:0;
}
.product-heading .con button{
	width:50px;
	height:50px;
	float:left;
	text-align:center;
	line-height:50px;
	background:#1b1b1b;
	border:0;
	color:#ffffff;
	-webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
	-webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.product-heading .con button i:before{
	margin-left:0;
}
.product-heading{
	float:right;
	margin-right:10px;
}
.prs_top_login_btn_wrapper{
	float:right;
	margin-right:10px;
}
.prs_slidebar_wrapper{
	float:right;
}
.prs_animate_btn1 .button {
	float: left;
	width: 150px;
	display: block;
	height:50px;
	line-height:50px;
	border: none;
	background: none;
	color: #ffffff !important;
	align-items: center;
	position: relative;
	z-index: 1;
	text-align:center;
	text-transform:uppercase;
	-webkit-backfaceVisibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	border:1px solid transparent;
	    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.prs_animate_btn1 .button:hover{
	border:1px solid #fff;
}
.prs_slidebar_searchbar_btn_wrapper .prs_animate_btn1 .button:hover{
	border:1px solid #000;
	color:#000 !important;
}
.prs_animate_btn1 .button--tamaya {
	overflow: hidden;
	color: #7986cb;
	min-width: 180px;
}
.prs_animate_btn1 .button--tamaya.button--inverted {
	color: #37474f;
	border-color: #37474f;
}
.prs_animate_btn1 .button--tamaya::before,
.prs_animate_btn1 .button--tamaya::after {
	content: attr(data-text);
	position: absolute;
	width: 100%;
	height: 50%;
	left: 0;
	background: #1b1b1b;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.prs_animate_btn1 .button--tamaya:hover::before,
.prs_animate_btn1 .button--tamaya:hover::after{
	overflow: hidden;
}
.prs_animate_btn1 .button--tamaya.button--inverted::before,
.prs_animate_btn1 .button--tamaya.button--inverted::after {
	background: #fff;
	color: #37474f;
}
.prs_animate_btn1 .button--tamaya::before {
	top: 0;
	padding-top: 1em;
}
.prs_animate_btn1 .button--tamaya::after {
	bottom: 0;
	line-height: 0;
}
.prs_animate_btn1 .button--tamaya > span {
	display: block;
	-webkit-transform: scale3d(0.2, 0.2, 1);
	transform: scale3d(0.2, 0.2, 1);
	opacity: 0;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.prs_animate_btn1 .button--tamaya:hover::before {
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
	
}
.prs_animate_btn1 .button--tamaya:hover::after {
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}
.prs_animate_btn1 .button--tamaya:hover > span {
	opacity: 1;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
	
}
.prs_animate_btn1 .prs_upcom_main_btn::before,
.prs_animate_btn1 .prs_upcom_main_btn::after {
	background:#ff4444;
}
.prs_upcom_main_btn{
	text-transform:capitalize !important;
}
.prs_upcom_main_btn:hover{
	border:1px solid #dedada !important;
	color:#ff4444 !important;
}
.prs_upcom_main_wrapper{
	text-align:center;
}
.prs_upcom_main_wrapper ul{
	display:inline-block;
	margin-top:29px;
}
/*-- prs sidebar Start --*/
#mobile-nav {
    position: fixed;
    top: 0;
	height:100%;
    right: -50%;
    width: 30%;
    z-index: 999;
    overflow-y: scroll;
	background:rgba(255, 255, 255, 0.9);
    transition: all 0.7s cubic-bezier(0.65, -0.22, 0.35, 1.13);
    -webkit-transition: all 0.7s cubic-bezier(0.65, -0.22, 0.35, 1.13);
    -moz-transition: all 0.7s cubic-bezier(0.65, -0.22, 0.35, 1.13);
    -o-transition: all 0.7s cubic-bezier(0.65, -0.22, 0.35, 1.13);
    overflow-x: hidden;
}
body.active #mobile-nav {
    right: 0 !important;
}
.mobile-nav-box {
    padding: 85px 30px;
    height: 100%;
    width: 100%;
    position: relative;
}

.mobile-nav-box::before {
    position: absolute;
    content: '';
    bottom: 0;
    right: -25px;
    height: 100%;
    width: 50%;
    background: url(../images/header/toggle-nav-bg-2.png) no-repeat;
    background-position: right;
    background-size: cover;
    z-index: 1;
}

.mobile-logo {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 50;
}

.mobile-logo .manu-close {
    margin-left: auto;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    color: #3c3c3c;
    cursor: pointer;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}

.mobile-logo .manu-close i {
    font-size: 18px;
}

.mobile-logo .manu-close:hover {
    color: #ff4444;
}

.mobile-main-logo {
    width: 200px;
    padding-left: 25px;
}

.mobile-main-logo img {
    width: 100%;
}

.mobile-list-nav {
    padding: 100px 0;
    position: relative;
    z-index: 90;
    padding-left: 25px;
}

.mobile-list-nav > li {
    padding: 15px 0;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}

.mobile-list-nav > li > a {
    font-size: 16px;
    color: #000000;
    position: relative;
    font-weight: bold;
    display: inline-block;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}

.mobile-list-nav > li > a.dropdownlink::after {
    position: absolute;
    top: 2px;
    right: -10px;
    content: '\f078';
    width: 5px;
    height: 10px;
    font-family: FontAwesome;
}

.mobile-list-nav > li:hover > a {
    color: #ff4444;
    transform: scale(1.1);
    transform: translateX(8px) scale(1.1);
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}
.mobile-nav-box .achivement-blog {
    position: relative;
    z-index: 10;
    text-align: center;
	padding-top: 20px;
    padding-bottom: 20px;
}

.mobile-nav-box .achivement-blog ul > li > a {
    color: #747474;
	transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}

.mobile-nav-box .achivement-blog ul > li > a span {
    color: #747474;
	transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}
.mobile-nav-box .achivement-blog ul > li > a h6 {
    color: #747474;
	transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}
.mobile-nav-box .achivement-blog ul > li > a:hover {
    color: #ff4444;
	transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}

.mobile-nav-box .achivement-blog ul > li > a:hover span {
    color: #ff4444;
	transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}
.mobile-nav-box .achivement-blog ul > li > a:hover h6 {
    color: #ff4444;
	transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}
.mobile-list-nav .submenuItems {

    display: none;
    padding: 0px;
    border-radius: 5px;
    margin-top: 17px;
}

.mobile-list-nav .submenuItems li a {
    color: #f39e67;
    padding: 20px;
    padding: 6px 20px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}
.prs_slidebar_wrapper button{
	width:50px;
	height:50px;
	background:#dd3333;
	border:0;
	    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	line-height:0;
}
.achivement-blog ul li {
    position: relative;
    color: #fff;
    padding: 5px 20px 5px 52px;
    text-align: left;
    width: 110px;
	float:left;
}

.achivement-blog ul li:first-child {
    padding-left: 28px;
}

.achivement-blog ul li:first-child i {
    left: 0;
}

.achivement-blog ul li:not(:last-child) {
    border-right: 1px solid #cdcbcb;
}

.achivement-blog ul li span {
    color: #cdcbcb;
}

.achivement-blog ul li i {
    position: absolute;
    top: 50%;
    left: 15px;
    font-size: 30px;
    transform: translateY(-50%);
    height: 30px;
    width: 30px;
}
.prs_slidebar_searchbar_btn_wrapper{
	float:left;
	width:100%;
}
.prs_slidebar_searchbar_wrapper{
	float:left;
	width:100%;
	position: relative;
    z-index: 100;
}
.achivement-blog{
	float:left;
	width:100%;
}
.prs_mobail_searchbar_wrapper{
	display:none;
	float:right;
}
.prs_mobail_searchbar_wrapper input{
	float:left;
	width:calc(100% - 30px);
	height:40px;
	border:0;
	padding-left:20px;
}
.prs_mobail_searchbar_wrapper button{
	float:left;
	width:30px;
	background:#1b1b1b;
	margin:0;
	height:40px;
	border-radius:0;
}
.prs_mobail_searchbar_wrapper a{
	float:left;
	width:120px;
	height:40px;
	line-height:30px !important;
	text-align:center;
	background:#000;
	color:#fff;
}
/*-- prs sidebar End --*/
/*-- prs video top Start --*/
.prs_top_video_section_wrapper{
	float:left;
	width:100%;
	padding-top:25px;
}
.st_video_slider_inner_wrapper{
	background:url('../images/header/blackImg.jpg') 50% 0 repeat-y;
	background-position:center 0;
	background-size:cover;
	    border: 6px solid #FFFFFF;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.38);
	    padding-top: 80px;
	    padding-bottom: 80px;
	position:relative;
}
.st_video_slider_overlay{
	position:absolute;
	left:0;
	right:0;
	top:0;
	bottom:0;
	background:rgba(0, 0, 0, 0.78);
}
.st_video_slide_sec{
	text-align:center;
	position:relative;
}
.st_video_slide_sec h3{
	font-size: 30px;
color: #FFFFFF;
letter-spacing: 2px;
font-weight:700;
padding-top:20px;
}
.st_video_slide_sec p{
	font-size: 12px;
color: #FFFFFF;
letter-spacing: 1px;
text-transform:uppercase;
padding-top:10px;
}
.st_video_slide_sec h4{
	text-transform:uppercase;
	font-size: 12px;
color: #FFFFFF;
letter-spacing: 1.2px;
padding-top:10px;
}
.st_video_slide_sec h5{
	display:inline-block;
	margin-top:15px;
}
.test-popup-link{
	border-radius:100%;
	display:inline-block;
	  box-shadow: 0 0 0 0 rgba(225,225,225,0.6);
    -webkit-animation: pulse 1.2s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.2s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulse 1.2s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.2s infinite cubic-bezier(0.66, 0, 0, 1);
}
@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 40px rgba(232, 76, 61, 0);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 40px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 40px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 40px rgba(232, 76, 61, 0);
  }
}
.st_video_slide_sec h5 span{
    width: auto;
	float:left;
    background: #eee;
    font-size: 12px;
    color: rgba(0,0,0,0.87);
    letter-spacing: 0;
    padding: 2px 5px;
    text-transform: uppercase;
	margin-left:10px;
	border-radius:2px;
}
.st_video_slide_social_left,.st_video_slide_social_right{
	float:left;
	width:50%;
}
.st_video_slide_social_left li{
	float:left;
	margin-right:10px;
}
.st_video_slide_social_left li a{
	    float: left;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
}
.st_video_slide_social_left li:first-child a{
	background:#3b5998;
}
.st_video_slide_social_left li:nth-child(2) a{
	background:#38a1f3;
}
.st_video_slide_social_left li:nth-child(3) a{
	background:#bd081c;
}
.st_video_slide_social_left li:last-child a{
	background:#cc3333;
}
.st_video_slide_social{
	padding:20px;
	position:relative;
}
.st_video_slide_social_right ul{
	float:right;
	    padding-top: 3px;
}
.st_video_slide_social_right li{
	float:left;
	margin-left:20px;
	    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0.86px;
}
.st_video_slide_social_right li i{
	    font-size: 18px;
    padding-right: 5px;
    position: relative;
    top: 3px;
}
.st_video_slide_social {
    padding: 20px;
    position: relative;
	    float: left;
    width: 100%;
}
.st_slider_rating_btn_heart_5th {
    position: absolute;
    left: 30px;
    top: -60px;
}
.st_slider_rating_btn_heart{
	float:right;
	width:auto;
	    padding-right: 30px;
}
.st_slider_rating_btn_heart h5{
	font-size: 26px;
	color: #F3C600;
	letter-spacing: 1px;
}
.st_slider_rating_btn_heart h5 i{
	color:#DBA506;
	position:relative;
	top: 18px;
    left: -5px;
	font-size: 55px;
}
.st_slider_rating_btn_heart h4{
	font-size: 14px;
	color: rgba(255,255,255,0.87);
	letter-spacing: 1.17px;
    /* padding-top: 1px; */
	margin-top: -15px;
    padding-left: 58px;
}
/*-- prs video top end --*/
/*-- kv slider wrapper Start --*/
.kv_main_slider_wrapper{
	float:left;
	width:100%;
}
.st_slider_img_wrapper img{
	width:100%;
	border: 6px solid #FFFFFF;
box-shadow: 0 4px 16px 0 rgba(0,0,0,0.38);
}
.st_slider_img_wrapper{
	padding-bottom:35px;
	padding-top: 30px;
}
.slider-area .carousel-inner .item .caption-1 {
  width:100%;
  height:100%;
  position: relative;
      min-height: 400px;
}
.slider-area .carousel-inner .item .caption-2 {
width:100%;
  height:100%;
  position: relative;
      min-height: 400px;
}
.slider-area .carousel-inner .item .caption-3 {
width:100%;
  height:100%;
  position: relative;
      min-height: 400px;
}
.kv_mains_slider_shaper{
	position:absolute;
	left:0;
	bottom:-90px;
}
.slider-area .carousel-inner .item .carousel-captions .content {
  display: block;
  position: relative;
  z-index: 999;
  align-items: center;
}
.st_slider_left_cont_main_wrapper{
	float:left;
	width:30%;
}
.st_slider_right_cont_main_wrapper{
	float:left;
	width:70%;
	padding-left:30px;
}
.slider-area .carousel-inner .item .carousel-captions .st_slider_left_contact{
	padding-top:75px;
}
.slider-area .carousel-inner .item .carousel-captions .content ul{
	display:inline-block;
}
.slider-area .carousel-inner .item .carousel-captions .content_tabs{
	display: block;
  position: relative;
  z-index: 999;
  align-items: center;
  padding-top:100px;
}
.slider-area .carousel-inner .item .carousel-captions .content h2 {
  font-size: 12px;
color: rgba(0,0,0,0.54);
letter-spacing: 0.86px;
  margin: 0 0 20px;
  animation-delay: 0.3s;
  text-transform:capitalize;
  margin-top:15px;
}
.slider-area .carousel-inner .item .carousel-captions .content h4 {
  font-size: 24px;
color: #ff4444;
letter-spacing: 1.2px;
  margin: 0 0 20px;
  animation-delay: 0.5s;
  text-transform:capitalize;
  margin-top:10px;
  font-weight:600;
}
.slider-area .carousel-inner .item .carousel-captions .content h4 span{
	width: auto;
    background: #00000026;
    font-size: 12px;
    color: rgba(0,0,0,0.38);
    letter-spacing: 0;
    padding: 2px 5px;
	text-transform:uppercase;
	    position: relative;
    top: -3px;
}
.slider-area .carousel-inner .item .carousel-captions .content .st_slider_list ul{
	margin-top:0 !important;
}
.slider-area .carousel-inner .item .carousel-captions .content .st_slider_list li i{
	font-size:16px;
	padding-right:5px;
}
.slider-area .carousel-inner .item .carousel-captions .content .st_slider_list li:last-child i{
	color: #ff4444;
}
.slider-area .carousel-inner .item .carousel-captions .content .st_slider_list li{
	font-size: 12px;
	color: rgba(0,0,0,0.54);
	letter-spacing: 0.86px;
	margin-top:10px;
	float:left;
	width:100%;
}
.slider-area .carousel-inner .item .carousel-captions .content .st_slider_list li:first-child{
	margin-top:0;
	animation-delay: 0.8s;
}
.slider-area .carousel-inner .item .carousel-captions .content .st_slider_list li:nth-child(2){
	animation-delay: 1.0s;
}
.slider-area .carousel-inner .item .carousel-captions .content .st_slider_list li:last-child{
	animation-delay: 1.3s;
}
.kv_slider_line{
	position:relative;
	animation-delay: 1.5s;
}
.kv_slider_line:after {
    content: '';
    border: 3px solid #06d1d8;
    width: 120px;
    position: absolute;
    bottom: -12px;
    left: 0;
}
.slider-area .carousel-inner .item .carousel-captions .content p{
	padding-top:40px;
	animation-delay: 2.3s;
	    line-height: 27px;
}
.slider-area .carousel-inner .item .carousel-captions .content h3 {
	font-size: 14px;
	color: rgba(0,0,0,0.54);
	letter-spacing: 1px;
	text-transform:uppercase;
	animation-delay: 0.1s;
}
.slider-area .carousel-inner .item .carousel-captions .content .prs_third_slider_btn li{
	animation-delay: 1.7s;
	float:left;
	margin-right:15px;
}
.prs_third_slider_btn{
	text-align:left;
}
.slider-area .carousel-inner .item .carousel-captions .content ul{
	margin-top:15px;
}
.slider-area .carousel-inner .carousel-indicators {
      right: 0;
    left: 0;
    bottom: 26px;
    margin: 0px auto;
}
.slider-area .carousel-inner .carousel-indicators li {
  text-indent: 0;
    position: relative;
    width: 60px;
    height: 4px;
    display: inline-block;
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-size: 16px;
    margin: 0;
    margin-right: 5px;
    text-align: left;
    background: rgba(0, 0, 0, 0.18);
    margin-top: 10px;
}
.slider-area .carousel-inner .carousel-indicators li span.number {
  display: inline-block;
}
.slider-area .carousel-inner .carousel-indicators li span.con {
  display: inline-block;
  position: relative;
  padding-left: 42px;
}
.slider-area .carousel-inner .carousel-indicators li span.con:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  background: #cf9c67;
  width: 26px;
}
.slider-area .carousel-inner .carousel-indicators li.active {
  background: #ff4444;
    width: 60px;
    height: 4px;
}
.carousel-nevigation{
	    position: absolute;
    float: left;
    width: 100%;
    left: 15%;
    margin-left: 10px;
    bottom: 100px;
	display:none;
}
.kv_main_slider_wrapper2 .carousel-nevigation{
	bottom: 130px;
}
.carousel-nevigation > .prev{
	left:0;
	    position: absolute;
    width: 55px;
    height: 55px;
    line-height: 45px;
    padding-right: 3px;
	font-size:30px;
	text-align:center;
    background: transparent;
	overflow:hidden;
	border: 2px solid rgba(0,0,0,0.12);
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	   -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.carousel-nevigation > .next{
	left:70px;
	    position: absolute;
    width: 55px;
    height: 55px;
	overflow:hidden;
    background: transparent;
	border: 2px solid rgba(0,0,0,0.12);
	font-size:30px;
	     line-height: 45px;
    padding-left: 5px;
	text-align:center;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.carousel-nevigation > .prev i:before,.carousel-nevigation > .next i:before{
	color:rgba(0, 0, 0, 0.37);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.carousel-nevigation > .next:hover,.carousel-nevigation > .prev:hover{
	border: 2px solid rgba(0,0,0,0.38);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.carousel-nevigation > .next:hover i:before,.carousel-nevigation > .prev:hover i:before{
	color:rgb(0, 0, 0);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.kv_main_slider_wrapper .carousel-inner{
	padding-bottom:60px;
}
/*-- kv slider wrapper End --*/
/*-- st slider sidebar wrapper Start --*/
.ne_businees_slider_wrapper  .owl-theme .owl-dots{
	display:none;
}
.ne_businees_slider_wrapper .owl-theme .owl-nav {
    position: absolute;
    top: -65px;
    margin: 0;
    left: 0;
    right: 0;
	z-index:1000;
	display:block;
}
.ne_businees_slider_wrapper .owl-theme .owl-nav .owl-prev{
    font-size: 20px;
    margin: 0;
    padding: 0;
    right: 40px;
    width: 40px;
    height: 40px;
    line-height: 37px;
	top:0;
	color:rgba(0,0,0,0.54);
	background:transparent;
	border: 1px solid rgba(0,0,0,0.12);
	border-right:0;
    position: absolute;
	border-radius:2px;
	border-top-right-radius:0;
	border-bottom-right-radius:0;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.ne_businees_slider_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 20px;
    margin: 0;
    padding: 0;
    right: 0;
	top:0;
	right:0;
	width: 40px;
    height: 40px;
    line-height: 37px;
    position: absolute;
	color:rgba(0,0,0,0.54);
	background:transparent;
	border: 1px solid rgba(0,0,0,0.12);
    text-align: center;
	border-radius:2px;
	border-top-left-radius:0;
	border-bottom-left-radius:0;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.ne_businees_slider_wrapper  .owl-theme .owl-nav .owl-next i:before,.ne_businees_slider_wrapper .owl-theme .owl-nav .owl-prev i:before{
	font-size:18px;
	margin-left:0;
}
.ne_businees_slider_wrapper .owl-theme .owl-nav .owl-prev:hover, .ne_businees_slider_wrapper  .owl-theme .owl-nav .owl-next:hover{
	color:#F06292;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.lazy{
	width:100%;
}
.lazy gif{
	width:auto !important;
}
.st_slider_index_sidebar_main_wrapper{
	padding-top:40px;
	padding-bottom:40px;
}
.ne_businees_slider_wrapper{
	margin-top:50px;
}
.ne_recent_heading_main_wrapper h2{
	    font-size: 20px;
    color: #000000;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;

}
.ne_recent_heading_main_wrapper h2:before {
    content: '';
    border: 2px solid #ff4444;
    width: 30px;
    position: absolute;
    bottom: -21px;
    left: 0;
    z-index: 1;
}
.ne_recent_heading_main_wrapper h2:after {
    content: '';
    border: 1px solid #dddddd;
    width: 100px;
    position: absolute;
    bottom: -20px;
    left: 0;
}
.ne_recent_heading_main_wrapper h2 a{
	    float: right;
    font-size: 14px;
    color: #F06292;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-right: 100px;
	    position: relative;
    z-index: 1;
}
.st_indx_slider_img{
	overflow:hidden;
	position:relative;
}
.st_indx_slider_img_inner_img{
	position:relative;
	overflow:hidden;
	    border-top-left-radius: 10px;
	    border-top-right-radius: 10px;
}
.st_slider_rating{
	float:left;
	width:65px;
	height:70px;
	text-align:center;
	background:#ffffff;
	position:absolute;
	top:0;
	left:0;
	padding-top:16px;
	border-bottom-right-radius:8px;
		
}
.st_slider_rating i{
	color:#DF1F00;
	font-size:20px;
}
.st_slider_rating p{
	font-size: 12px;
color: rgba(0,0,0,0.87);
letter-spacing: 0;
    line-height: 12px;
}
.st_slider_rating .date_tag{
	    font-size: 14px;
    color: #DF1F00;
    letter-spacing: 0;
    line-height: 12px;
    padding-bottom: 6px;
}
.st_ind_seconf_slider{
	margin-top:60px;
}
.st_ind_sidebar_advertiz img{
	width:100%;
	    border: 6px solid #FFFFFF;
    box-shadow: 0 8px 6px 0 rgba(0,0,0,0.12);
    border-radius: 5px;
}
.st_ind_sidebar_search{
	margin-top:30px;
	-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
}
.st_ind_sidebar_search_heading{
border-radius: 4px 4px 0px 0px;
    padding: 12px 20px;
}
.st_ind_sidebar_search_heading p{
	    font-size: 16px;
    color: #000000;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
padding-bottom: 20px;
}
.st_ind_sidebar_search_heading p:before {
    content: '';
    border: 2px solid #ff4444;
    width: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.st_ind_sidebar_search_heading p:after {
    content: '';
    border: 1px solid #dddddd;
    width: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.st_ind_sidebar_search_ul{
	background:#fff;
	border-radius: 4px;
	border-top-left-radius:0;
	border-top-right-radius:0;
	    padding: 20px;
		    padding-top: 0;
}
.st_ind_sidebar_search_small_box p a{
	font-size: 16px;
color: rgba(0,0,0,0.87);
    line-height: 4px;
}
.st_ind_sidebar_search_small_box p a:hover{
	    color: #ff4444;
}
.st_ind_sidebar_search_small_box span{
	font-size: 10px;
color: rgba(0,0,0,0.38);
letter-spacing: 0.67px;
text-transform:uppercase;
}
.st_ind_sidebar_search_small_box{
	    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
	position:relative;
	    padding-top: 20px;
}
.st_ind_sidebar_search_small_box .st_sidebar_search_titte_count{
	position:absolute;
	right:0;
	top:16px;
	font-size: 10px;
color: #ff4444;
letter-spacing: 0.67px;
}
.st_notes_wrapper h4{
	font-size: 12px;
	color: rgba(0,0,0,0.54);
	letter-spacing: 1px;
	line-height: 14px;
	font-weight:800;
}
.st_notes_wrapper h5{
	font-size: 12px;
color: rgba(0,0,0,0.54);
letter-spacing: 1px;
padding-top:5px;
    line-height: 18px;
}
.st_notes_wrapper h5 a{
	color: #F06292;
}
.st_notes_wrapper{
	padding-top:60px;
}
.cc_pc_accordion{
	float:left;
	width:100%;
	margin-top: 30px;
}
.cc_pc_first_accordion_heading_wrapper h2{
	font-size:16px;
	font-weight:bold;
	text-transform:uppercase;
}
.cc_pc_second_accordion_wrapper{
	float: left;
    width: 100%;
    margin-top: 40px;
}
.cc_pc_second_accordion_wrapper2{
	margin-top:0;
}
.cc_pc_accordion .box {
      color: #ffffff;
    /* margin-top: 23px; */
    text-align: left;
    /* margin-left: 26px; */
    padding: 10px;
    padding-left: 25px;
    padding-bottom: 30px;
}
.cc_pc_accordion input[type="checkbox"] {
  display: none;
  
}
.cc_pc_accordion label {
  cursor: pointer;
  font-size: 10px;
color: rgba(0,0,0,0.54);
letter-spacing: 0.83px;
text-transform:uppercase;
    font-weight: 600;
}
.cc_pc_accordion input[type="checkbox"] + label:before {
  border: 1px solid #010101;
  opacity: 0.54;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0.2em 0.56em .0 .0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius:3px;
}
.cc_pc_accordion input[type="checkbox"]:checked + label:before {
	border: 0;
    color: #F06292;
    content: "\2713";
    font-weight: 900;
    background: transparent;
	border: 1px solid #010101;
}
.box p{
	padding-top:10px;
	padding-bottom:10px;
}
.cc_pc_accordion input[type="checkbox"]:checked + label:after {
  font-weight: 900;
}
.cc_pc_color_accordion .box {
      color: #ffffff;
    margin-top: 23px;
    text-align: left;
    margin-left: 26px;
}
.cc_pc_color_accordion input[type="checkbox"] {
  display: none;
  
}
.cc_pc_color_accordion label {
  cursor: pointer;
  color:#797979;
  font-size: 16px;
}
.cc_pc_color_accordion input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}.accordion {
 	width: 100%;
 	max-width: 360px;
 	background: #FFF;
 	-webkit-border-radius: 4px;
 	-moz-border-radius: 4px;
 	border-radius: 4px;
	border:1px solid #eeeeee;
 }

.accordion .link {
	cursor: pointer;
	display: block;
	padding: 15px 15px 15px 42px;
	text-transform: uppercase;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 1px;
    background: #333545;
    border-radius: 4px 4px 0px 0px;
	position: relative;
	-webkit-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.accordion li i {
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 12px;
	color: #fff;
	-webkit-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.accordion li i.fa-chevron-down {
	right: 20px;
    top: 20px;
    font-size: 12px;
}

.accordion li.open .link {
	text-transform:uppercase;
	font-size: 12px;
color: #FFFFFF;
letter-spacing: 1px;
	background: #333545;
border-radius: 4px 4px 0px 0px;
}

.accordion li.open i {
	color: #fff;
}
.cc_pc_icon{
	font-size:20px !important;
}
.accordion li.open i.fa-chevron-down {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
.accordion li.default .submenu {
	display: block;
	
}
.accordion .cc_product_heading{
	padding-left:26px !important;
	    text-transform: uppercase;
    font-weight: bold;
}
.accordion .cc_product_heading i{
	top:18px;
}
/**
 * Submenu
 -----------------------------*/
 .submenu {
 	display: none;
 	background: #ffffff;
 	font-size: 14px;
	border: 0;
 }
 .cc_pc_accordion2{
	 margin-top:10px;
 }
 .submenu a {
 	display: block;
 	text-decoration: none;
 	color: #000000;
 	padding: 12px;
 	padding-left: 42px;
 	-webkit-transition: all 0.25s ease;
 	-o-transition: all 0.25s ease;
 	transition: all 0.25s ease;
 }

 .submenu a:hover {
 	background: #1887e5;
 	color: #FFF;
}
.st_indx_tabs_ul .nav{
    display: inline-block;
	background:transparent;
	box-shadow:0;
	    border-bottom: 0;
		    margin-top: 3px;

}
.st_tabs_2{
	margin-top:30px;
}
.st_indx_tabs_ul .nav-tabs li:first-child .nav-link.active{
	 -webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.st_indx_tabs_ul .nav-tabs li:last-child .nav-link.active{
	 -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
	-webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.st_indx_tabs_ul .nav-tabs .nav-link:focus, .st_indx_tabs_ul .nav-tabs .nav-link:hover{
	border-color:transparent;
}
.st_indx_tabs_ul .nav li{
	float:left;
}
.st_indx_tabs_ul .nav-tabs .nav-item.show .nav-link, .st_indx_tabs_ul .nav-tabs .nav-link.active {
   color: rgba(0,0,0,0.87);
    background-color:transparent;
	border-color:transparent;
	font-weight:600;
	text-transform:uppercase;
	font-family: 'Roboto', sans-serif;
	position:relative;
}
.st_indx_tabs_ul .nav-tabs .nav-link.active:after{
	content:'';
	border:2px solid #F06292;
	position:absolute;
	left:0;
	top:-3px;
	width:60px;
}
.st_indx_tabs_ul .nav-tabs .nav-link{
	font-size: 16px;
color: rgba(0,0,0,0.38);
letter-spacing: 1px;
font-weight:600;
	text-transform:uppercase;
	    padding: 12px 30px;
		padding-left:0;
	font-family: 'Roboto', sans-serif;
}
.prs_upcom_movie_box_wrapper{
	float:left;
	width:90%;
	height: 600px;
	margin-bottom: 10px;
}
.prs_upcom_movie_img_box{
	float:left;
	width:100%;
	position:relative;
	overflow:hidden;
}
.prs_upcom_movie_img_overlay{
	position:absolute;
	top:200px;
	right:200px;
	left:200px;
	bottom:200px;
	background:rgba(0,0,0,0.5);
	opacity:0;
	-webkit-transition:all 0.55s linear;
    -moz-transition:all 0.55s linear;
    -ms-transition:all 0.55s linear;
    -o-transition:all 0.55s linear;
    transition:all 0.55s linear;
}
.prs_upcom_movie_img_btn_wrapper{
	float:left;
	width:100%;
	text-align:center;
}
.prs_upcom_movie_img_btn_wrapper ul{
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 30%;
    left: 0;
}
.prs_upcom_movie_img_btn_wrapper li{
	float:none;
}
.prs_upcom_movie_img_btn_wrapper li:first-child a{
	display:inline-block;
	width:150px;
	height:50px;
	text-align:center;
	line-height:50px;
	background:#ff4444;
	border:1px solid #ff4444;
	color:#ffffff;
	margin-left:-900px;
	    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_upcom_movie_img_btn_wrapper li:first-child a:hover{
	background:transparent;
	border:1px solid #ff4444;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_upcom_movie_img_btn_wrapper li:last-child a{
	display:inline-block;
	width:150px;
	height:50px;
	text-align:center;
	line-height:50px;
	border:1px solid #ff4444;
	margin-right:-900px;
	background:transparent;
	color:#ffffff;
	margin-top:10px;
	    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_upcom_movie_img_btn_wrapper li:last-child a:hover{
	background:#ff4444;
	border:1px solid #ff4444;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_upcom_movie_box_wrapper:hover .prs_upcom_movie_img_btn_wrapper li:first-child a{
	margin-left:0;
	-webkit-transition:all 0.25s linear;
    -moz-transition:all 0.25s linear;
    -ms-transition:all 0.25s linear;
    -o-transition:all 0.25s linear;
    transition:all 0.25s linear;
}
.prs_upcom_movie_box_wrapper:hover .prs_upcom_movie_img_btn_wrapper li:last-child a{
	margin-right:0;
	-webkit-transition:all 0.50s linear;
    -moz-transition:all 0.50s linear;
    -ms-transition:all 0.50s linear;
    -o-transition:all 0.50s linear;
    transition:all 0.50s linear;
}
.prs_upcom_movie_box_wrapper:hover .prs_upcom_movie_img_overlay{
	position:absolute;
	top:0;
	right:0;
	left:0;
	bottom:0;
	background:rgba(0,0,0,0.5);
	opacity:1;
	-webkit-transition:all 0.35s linear;
    -moz-transition:all 0.35s linear;
    -ms-transition:all 0.35s linear;
    -o-transition:all 0.35s linear;
    transition:all 0.35s linear;
	-webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
}
.prs_upcom_movie_img_box img{
	width:100%;
	-webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
}
.prs_upcom_movie_content_box{
	float:left;
	width:100%;
	background:#ffffff;
	padding:20px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
	overflow:hidden;
	    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_upcom_movie_box_wrapper:hover .prs_upcom_movie_content_box{
	    -webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_upcom_movie_content_box h2 a{
	font-size:18px;
	color:#000000;
	font-weight:600;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_upcom_movie_content_box h2 a:hover{
	color:#ff4444;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_upcom_movie_content_box p{
	padding-top:5px;
	padding-bottom:5px;
	margin-right:-200px;
}
.prs_upcom_movie_content_box i{
	color:#ff4444;
}
.prs_upcom_movie_content_box_inner{
	float:left;
	width:75%;
}
.prs_upcom_movie_content_box_inner_icon{
	float:left;
	width:25%;
	padding-top:20px;
}
.prs_upcom_movie_content_box_inner_icon li{
	float:right;
}
.prs_upcom_movie_content_box_inner_icon li a{
	width:50px;
	height:50px;
	line-height:50px;
	text-align:center;
	float:left;
	border:1px solid #eeeeee;
	color:#ff4444;
	    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_upcom_movie_content_box_inner_icon li a:hover{
	background:#ff4444;
	border:1px solid #ff4444;
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_upcom_movie_content_box_inner_icon li a:hover i{
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_upcom_movie_content_box_inner_icon li a i:before{
	margin-left:0;
}
.st_ind_sidebar_search_small_box:last-child{
	border-bottom:0;
}
.st_slider_index_sidebar_main_wrapper{
	width:100%;
	float:left;
}
/*-- st slider sidebar wrapper End --*/
/*-- prs patner slider Start --*/
.prs_patner_main_section_wrapper{
	float:left;
	width:100%;
	background:#ffffff;
	padding-top:94px;
	padding-bottom:80px;
}
.prs_pn_slider_wraper{
	float:left;
	width:100%;
	margin-top:80px;
}
.prs_pn_slider_wraper  .owl-theme .owl-dots{
	display:none;
}
.prs_pn_slider_wraper .owl-theme .owl-nav {
    position: absolute;
    top: 30%;
    z-index: 1000;
    display: block;
	left:0;
	right:0;
	margin:0px auto;
}
.prs_pn_slider_wraper .owl-theme .owl-nav .owl-prev{
    margin: 0;
    padding: 0;
    left: -75px;
	font-size: 26px;
	top:0;
	color:#ffffff;
	width:50px;
	height:50px;
	padding-left: 5px;
    line-height: 45px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.prs_pn_slider_wraper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
    right: -75px;
	top:-5px;
    position: absolute;
	color:#ffffff;
	width:50px;
	padding-left: 12px;
    height: 50px;
    line-height: 39px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_pn_slider_wraper  .owl-theme .owl-nav .owl-next i:before,
.prs_pn_slider_wraper .owl-theme .owl-nav .owl-prev i:before{
	margin-left:0;
}
.prs_pn_img_wrapper img{
	width:100%;
	opacity:0.4;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_pn_img_wrapper img:hover{
	opacity:1;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_patner_main_section_wrapper_ind3{
	background:#f6f6f6;
}
.prs_heading_section_wrapper{
	float:left;
	width:100%;
	text-align:center;
	padding-bottom: 10px;
}
.prs_heading_section_wrapper h2{
	font-size:32px;
	color:#000000;
	text-transform:uppercase;
	font-weight:bold;
	position:relative;
}
.prs_heading_section_wrapper h2:after{
	content:'';
	border:1px solid #dddddd;
	width:170px;
	position:absolute;
	bottom:-20px;
	left:0;
	right:0;
	margin:0px auto;
}
.prs_heading_section_wrapper h2:before{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	bottom:-21px;
	left:0;
	right:0;
	margin:0px auto;
	z-index:1;
}
/*-- prs patner slider End --*/
/*-- prs Newsletter css Start --*/
.prs_newsletter_wrapper{
	float:left;
	width:100%;
	background-color:#ff4444;
	padding-top:60px;
	padding-bottom:53px;
}
.prs_newsletter_text{
	float:left;
	width:100%;
}
.prs_newsletter_text h3{
    float: left;
    width: 100%;
    font-size: 30px;
    color: #ffffff;
    margin: 12px 0px;
	font-weight:bold;
    text-transform: uppercase;
}
.prs_newsletter_field{
	float:left;
	width:100%;
}
.prs_newsletter_field input{
	float:left;
	width:calc(100% - 160px);
	border:2px solid rgba(255, 255, 255, 0.33);
	height:50px;
	line-height:50px;
	padding:0px 15px;
	color:#ffffff;
	border-right:0;
	background-color:transparent;
	-webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.prs_newsletter_field input::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.52);
}
.prs_newsletter_field input::-moz-placeholder {
	color: rgba(255, 255, 255, 0.52);
}
.prs_newsletter_field input:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.52);
}
.prs_newsletter_field input:-moz-placeholder {
	color: rgba(255, 255, 255, 0.52);
}
.prs_newsletter_field button{
	float:left;
	width:160px;
	height:50px;
	font-weight:bold;
	background-color:#ffffff;
	color:#ff4444;
	border:0px;
	letter-spacing:1px;
	    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.prs_newsletter_field button:hover{
	background:#000000;
	color:#ffffff;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
/*-- prs Newsletter css End --*/
/*-- prs footer Wrapper Start --*/
.prs_footer_main_section_wrapper{
	background:#111111;
	float:left;
	width:100%;
	height:100%;
	padding-top:60px;
	padding-bottom:60px;
}
.prs_footer_cont1_wrapper{
	float:left;
	width:100%;
}
.prs_footer_cont1_wrapper h2{
	font-size:18px;
	font-weight:bold;
	color:#ffffff;
	position:relative;
	text-transform:uppercase;
}
.prs_footer_cont1_wrapper h2:after{
	content:'';
	border:1px solid rgba(255, 255, 255, 0.28);
	width:90px;
	position:absolute;
	bottom:-20px;
	left:0;
}
.prs_footer_cont1_wrapper h2:before{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	bottom:-21px;
	left:0;
}
.prs_footer_cont1_wrapper p{
	font-size:15px;
	padding-top: 55px;
	color:rgba(255, 255, 255, 0.57);
}
.prs_footer_cont1_wrapper ul{
	padding-top:45px;
}
.prs_footer_cont1_wrapper_4 ul{
	padding-top:10px;
}
.prs_footer_cont1_wrapper_4 li{
	float:left;
	width:49%;
	margin-top:0;
}
.prs_footer_cont1_wrapper_4 h5{
	font-size:18px;
	font-family: 'Lato', sans-serif;
	color:rgba(255, 255, 255, 0.57);
	padding-top:25px;
	float: left;
    width: 100%;
}
.prs_bottom_footer_cont_wrapper p{
	color:rgba(255, 255, 255, 0.57);
}
.prs_footer_cont1_wrapper_4 h5 span{
	color:#ff4444;
}
.prs_footer_cont1_wrapper_4 li:last-child{
	margin-left:5px;
}
.prs_footer_cont1_wrapper_4 li img{
	width:100%;
}
.prs_footer_cont1_wrapper li i{
	color:#ff4444;
	font-size: 10px;
    position: relative;
    top: -1px;
}
.prs_footer_cont1_wrapper li{
	margin-top:10px;
}
.prs_footer_cont1_wrapper li a{
	color:rgba(255, 255, 255, 0.57);
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.prs_footer_cont1_wrapper li a:hover{
	color:#ff4444;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;    
}
.prs_footer_cont1_wrapper li a img{
	-webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.footer_img{
	margin-top:20px !important;
}
.prs_footer_social_wrapper{
	float:left;
	width:100%;
}
.prs_footer_social_wrapper h2{
	font-size:18px;
	font-weight:bold;
	color:#ffffff;
	text-transform:uppercase;
}
.prs_footer_social_wrapper ul{
	float:right;
}
.prs_footer_social_wrapper li{
	float:left;
	margin-right:10px;
}
.prs_footer_social_wrapper li a{
	float:left;
	width:30px;
	height:30px;
	line-height:30px;
	text-align:center;
	background:transparent;
	border:1px solid rgba(255, 255, 255, 0.37);
	color:rgba(255, 255, 255, 0.75);
	-webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s; 
}
.prs_footer_social_wrapper li a:hover{
	border:1px solid #ff4444;
	background:#ff4444;
	color:#ffffff;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s; 
}
.prs_bottom_footer_wrapper{
	float:left;
	width:100%;
	background:#0e0e0e;
	padding-top:20px;
	padding-bottom:20px;
}
.prs_bottom_footer_cont_wrapper p a{
	color:#ff4444;
	font-size:14px;
}
/*-- prs footer Wrapper End --*/
/************************----------------- ABOUT CSS START ----------------**************/
/*-- prs title wrapper Start --*/
.prs_title_main_sec_wrapper{
	background:url('../images/header/bann.jpg') 70% 0 repeat-y;
	background-repeat:no-repeat;
	background-position:center 0;
	background-size:cover;
	width:100%;
	height:100%;
	float:left;
	position:relative;
	padding-top:75px;
	text-align:center;
}
.prs_title_img_overlay{
	position:absolute;
	left:0%;
	right:0%;
	top:0%;
	bottom:0%;
	background:rgba(0,0,0,0.8);
}
.prs_title_heading_wrapper{
	float:left;
	width:100%;
}
.prs_title_heading_wrapper h2{
	font-size:36px;
	color:#ffffff;
	font-weight:bold;
	text-transform:uppercase;
}
.prs_title_heading_wrapper ul{
	display:inline-block;
	background:rgba(255, 255, 255, 0.13);
	padding:10px 30px;
	margin-top:40px;
	position: relative;
    top: 4px;
}
.prs_title_heading_wrapper li{
	float:left;
	color:#ff4444;
}
.prs_title_heading_wrapper li a{
	color:#ffffff;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s; 
}
.prs_title_heading_wrapper li a:hover{
	color:#ff4444;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s; 
}
/*-- prs title wrapper End --*/
/*-- prs about presenter wrapper Start --*/
.prs_about_presenter_main_wrapper{
	float:left;
	width:100%;
	background:#f6f6f6;
	padding-top:94px;
	padding-bottom:100px;
}
.prs_about_pre_left_sec_wrapper{
	float:left;
	width:100%;
	margin-top:80px;
}
.prs_ap_left_img_wrapper{
	float:left;
	width:100%;
}
.prs_ap_left_img_wrapper img{
	width:100%;
}
.prs_ap_left_img_cont_wrapper{
	float:left;
	width:100%;
	padding-top:40px;
}
.prs_ap_left_img_cont_wrapper h2{
	font-size:20px;
	font-weight:bold;
	color:#000000;
	text-transform:uppercase;
	position:relative;
}
.prs_ap_left_img_cont_wrapper h2:after{
	content: '';
    border: 2px solid #ff4444;
    width: 30px;
    position: absolute;
    bottom: -15px;
    left: 0;
}
.prs_ap_left_img_cont_wrapper h2:before{
	content: '';
    border: 1px solid #e2e2e2;
    width: 85px;
    position: absolute;
    bottom: -14px;
    left: 0;
}
.prs_ap_left_img_cont_wrapper p{
	padding-top:45px;
}
.prs_about_pre_right_sec_wrapper{
	float:left;
	width:100%;
	margin-top:80px;
}
.prs_about_pre_right_slider_sec_wrapper{
	float:left;
	width:100%;
}
.prs_about_pre_right_slider_sec_wrapper  .owl-theme .owl-dots{
	display:none;
}
.prs_about_pre_right_slider_sec_wrapper .owl-theme .owl-nav {
    position: absolute;
    top: 40%;
    z-index: 1000;
    display: block;
	left:0;
	right:0;
	margin:0px auto;
}
.prs_about_pre_right_slider_sec_wrapper .owl-theme .owl-nav .owl-prev{
    margin: 0;
    padding: 0;
    left: 20px;
	font-size: 26px;
	top:0;
	color:#ffffff;
	width:50px;
	height:50px;
	padding-left: 5px;
    line-height: 45px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.prs_about_pre_right_slider_sec_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
    right: 20px;
	top:-5px;
    position: absolute;
	color:#ffffff;
	width:50px;
	padding-left: 12px;
    height: 50px;
    line-height: 39px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_about_pre_right_slider_sec_wrapper  .owl-theme .owl-nav .owl-next i:before,
.prs_about_pre_right_slider_sec_wrapper .owl-theme .owl-nav .owl-prev i:before{
	margin-left:0;
}
.prs_about_pre_right_slider_sec_cont_wrapper{
	float:left;
	width:100%;
	background:#ffffff;
	padding:60px;
	    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;	

}
.prs_about_pre_right_sec_wrapper:hover .prs_about_pre_right_slider_sec_cont_wrapper{
	-webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_about_pre_right_slider_sec_cont_wrapper h2{
	font-size:20px;
	font-weight:bold;
	color:#000000;
	text-transform:uppercase;
	position:relative;
}
.prs_about_pre_right_slider_sec_cont_wrapper h2:after{
	content: '';
    border: 2px solid #ff4444;
    width: 30px;
    position: absolute;
    bottom: -15px;
    left: 0;
}
.prs_about_pre_right_slider_sec_cont_wrapper h2:before{
	content: '';
    border: 1px solid #e2e2e2;
    width: 85px;
    position: absolute;
    bottom: -14px;
    left: 0;
}
.prs_about_pre_right_slider_sec_cont_wrapper p{
	padding-top:45px;
}
.prs_about_pre_right_slider_sec_cont_wrapper ul{
	padding-top:30px;
	float:left;
	width:50%;
}
.prs_about_pre_right_slider_sec_cont_wrapper li i{
	color:#ff4444;
}
.prs_about_vp_main_section_wrapper{
	background:#ffffff;
}
/*-- prs about presenter wrapper End --*/
/*-- prs about team section Start --*/
.prs_about_team_main_wrapper{
	float:left;
	width:100%;
	background:#f6f6f6;
	padding-top:94px;
	padding-bottom:94px;
}
.prs_about_team_first_mem_wrapper{
	float:left;
	width:100%;
	margin-top:80px;
	text-align:center;
	margin-bottom:10px;
}
.prs_about_first_mem_img_wrapper{
	float:left;
	width:100%;
	position:relative;
	overflow:hidden;
}
.prs_about_first_mem_img_wrapper img{
	width:100%;
	    -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
}
.prs_about_first_mem_img_cont_wrapper{
	float:left;
	width:100%;
	background:#ffffff;
	    -webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
	padding-top:30px;
	padding-bottom:30px;
	-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_about_team_first_mem_wrapper:hover .prs_about_first_mem_img_cont_wrapper{
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
}
.prs_about_first_mem_img_cont_inner_wrapper{
	padding-top:10px;
	padding-bottom:10px;
}
.prs_about_first_mem_img_cont_wrapper h2 a{
	color:#000000;
	font-weight:bold;
	font-size:18px;
	text-transform:uppercase;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_about_team_first_mem_wrapper:hover .prs_about_first_mem_img_cont_wrapper h2 a{
	color:#ff4444;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_at_social_main_wrapper{
	float:left;
	width:100%;
	text-align:center;
	position: absolute;
    bottom: -100px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_about_team_first_mem_wrapper:hover .prs_at_social_main_wrapper{
	 bottom: -5px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_at_social_main_wrapper ul{
	display:inline-block;
	background:rgba(255, 68, 68, 0.83);
	padding:10px 30px;
	    -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
}
.prs_at_social_main_wrapper li{
	float:left;
	margin-right:20px;
}
.prs_at_social_main_wrapper li a{
	color:#ffffff;
	float:left;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_at_social_main_wrapper li a:hover{
	transform: scale(1.2);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_at_social_main_wrapper li{
	position:relative;
	top:100px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_about_team_first_mem_wrapper:hover .prs_at_social_main_wrapper li:first-child{
	top:0;
	-webkit-transition:all 0.45s linear;
    -moz-transition:all 0.45s linear;
    -ms-transition:all 0.45s linear;
    -o-transition:all 0.45s linear;
    transition:all 0.45s linear;
}
.prs_about_team_first_mem_wrapper:hover .prs_at_social_main_wrapper li:nth-child(2){
	top:0;
	-webkit-transition:all 0.55s linear;
    -moz-transition:all 0.55s linear;
    -ms-transition:all 0.55s linear;
    -o-transition:all 0.55s linear;
    transition:all 0.55s linear;
}
.prs_about_team_first_mem_wrapper:hover .prs_at_social_main_wrapper li:nth-child(3){
	top:0;
	-webkit-transition:all 0.65s linear;
    -moz-transition:all 0.65s linear;
    -ms-transition:all 0.65s linear;
    -o-transition:all 0.65s linear;
    transition:all 0.65s linear;
}
.prs_about_team_first_mem_wrapper:hover .prs_at_social_main_wrapper li:last-child{
	top:0;
	-webkit-transition:all 0.75s linear;
    -moz-transition:all 0.75s linear;
    -ms-transition:all 0.75s linear;
    -o-transition:all 0.75s linear;
    transition:all 0.75s linear;
}
.prs_at_social_main_wrapper li:last-child{
	margin-right:0;
}
.prs_about_team_first_mem_wrapper2{
	margin-top:45px;
}
.prs_ap_btn_wrapper ul{
	margin-top:49px;
}
/*-- prs about team section End --*/
/*-- counter section start --*/
.counter_section {
    background: url('../images/content/about/counter_bg.jpg') 50% 0 repeat-y;
    width: 100%;
    height: 100%;
	text-align:center;
	padding-bottom:57px;
	float:left;
	background-attachment:fixed;
}
.trucking_counter .con{
	width:95px;
	height:85px;
	background:#ff4444;
	text-align:center;
	line-height:80px;
	display:inline-block;
	color:#fff;
	font-size:30px;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	position:relative;
	z-index:1;
}
.trucking_counter .con:after{
	content: '';
    width: 65px;
    height: 10px;
    background: #e74343;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0px auto;
    top: -10px;
    z-index: -1;
	-webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
}
.trucking_counter{
	margin-top:70px;
}
.count-description {
	margin-top:30px;
}
.count-description span{
	font-size:40px;
	color:#fff;
	margin-top:30px;
	font-weight:900;
}
.con1, .con2, .con3, .con4{
	font-family: 'Lato', sans-serif;
	font-size: 20px;
	color:#fff;
	margin-top:20px;
}
.counter_btn{
	display:inline-block;
	margin-top:9px;
}
.counter_btn ul{
	padding:0;
	list-style:none;
	margin-top:40px;
}
.counter_btn li a{
	width:205px;
	height:50px;
	float:left;
	background:transparent;
	text-align:center;
	line-height:45px;
	border-radius:50px;
	color:#fff;
	font-family: 'Titillium Web', sans-serif;
	font-size:18px;
	font-weight:bold;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	letter-spacing:2px;
	border:2px solid #fca900;
}
.counter_btn li{
	position:relative;
}
.counter_btn li:after{
	content:'';
	border:1px solid rgba(255, 255, 255, 0.06);
	width: 191%;
    display: inline-block;
    position: absolute;
    margin-left: 15px;
    margin-top: 22px;
}
.counter_btn li:before{
	content:'';
	border:1px solid rgba(255, 255, 255, 0.06);
	width: 191%;
    display: inline-block;
    position: absolute;
    margin-right: 15px;
    margin-top: 22px;
	left: -412px;
}
.counter_btn li a:hover{
	background:#fca900;
	border:2px solid transparent;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	color:#fff;
}
/*-- counter section end --*/
/************************----------------- ABOUT CSS End ----------------**************/
/************************----------------- MOVIE CATEGORY CSS Start ----------------**************/
/*-- prs mc slider wrapper Start --*/
.prs_mc_slider_main_wrapper{
	float:left;
	width:100%;
	padding-top:94px;
	padding-bottom:100px;
	background:#f6f6f6;
}
.prs_mc_slider_wrapper{
	float:left;
	width:100%;
	margin-top:70px;
}
.prs_mc_slider_wrapper img{
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.prs_mc_slider_wrapper  .owl-theme .owl-dots{
	display:none;
}
.prs_mc_slider_wrapper .owl-theme .owl-nav {
    position: absolute;
    top: 46%;
    z-index: 1000;
    display: block;
	left:0;
	right:0;
	margin:0px auto;
}
.prs_mc_slider_wrapper .owl-theme .owl-nav .owl-prev{
    margin: 0;
    padding: 0;
    left: -80px;
	font-size: 26px;
	top:0;
	color:#ffffff;
	width:50px;
	height:50px;
	padding-left: 5px;
    line-height: 45px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.prs_mc_slider_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
    right: -80px;
	top:-5px;
    position: absolute;
	color:#ffffff;
	width:50px;
	padding-left: 12px;
    height: 50px;
    line-height: 39px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mc_slider_wrapper  .owl-theme .owl-nav .owl-next i:before,
.prs_mc_slider_wrapper .owl-theme .owl-nav .owl-prev i:before{
	margin-left:0;
}
.prs_mc_category_sidebar_main_wrapper{
	float:left;
	width:100%;
	background:#f6f6f6;
	padding-bottom:95px;
}
.prs_mcc_left_side_wrapper{
	float:left;
	width:100%;
	background:#ffffff;
	padding:40px 30px;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;	
}
.prs_mcc_left_side_wrapper:hover{
	 -webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_right_side_wrapper{
	float:left;
	width:100%;
}
.prs_mcc_right_side_heading_wrapper{
	float:left;
	width:100%;
	margin-bottom:30px;
}
.prs_mcc_right_side_heading_wrapper h2{
	font-size:26px;
	color:#000000;
	text-transform:uppercase;
	font-weight:bold;
	position:relative;
}
.prs_mcc_right_side_heading_wrapper h2:after{
	content:'';
	border:1px solid #dddddd;
	width:100%;
	position:absolute;
	bottom:-20px;
	left:0;
}
.prs_mcc_right_side_heading_wrapper h2:before{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	bottom:-21px;
	left:0;
	z-index:1;
}
.prs_mcc_right_side_heading_wrapper ul{
	float:right;
	margin-top:-34px;
}
.prs_mcc_right_side_heading_wrapper .nav-pills > li.active > a, .prs_mcc_right_side_heading_wrapper .nav-pills > li.active > a:hover, .prs_mcc_right_side_heading_wrapper .nav-pills > li.active > a:focus{
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	background:#ff4444;
}
.prs_mcc_right_side_heading_wrapper .nav-pills > li > a{
	background:#ffffff;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
}
.prs_mcc_right_side_heading_wrapper .nav-pills > li:first-child{
	margin-right:5px;
}
.prs_mcc_movie_box_wrapper{
	margin-top:20px;
}
.pagination{
	margin:0;
	padding-top:45px;
}
.pagination > li > a{
	color:#000000;
	background:#ffffff;
	font-size:16px;
	border:1px solid #e7e7e7;
	text-transform:uppercase;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	width:50px;
	height:50px;
	line-height:35px;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	text-align:center;
	margin-right:10px;
}
.pagination > li:first-child > a{
	width:50px;
	height:50px;
	text-align:center;
	border:1px solid #ff4444;
	margin-right:20px;
	background:#ff4444;
	color:#ffffff;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.pagination > li a i:before{
	margin-left:0;
} 
.pagination > li:last-child > a{
	width:50px;
	height:50px;
	text-align:center;
	border:1px solid #ff4444;
	margin-left:10px;
	background:#ff4444;
	color:#ffffff;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}	
.pagination > li:first-child:hover > a{
	background:#111111;
	border:1px solid transparent;
}
.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    z-index: 2;
    color: #fff;
	border:1px solid transparent;
    background-color: #111111;
    border-color: none;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_left_searchbar_wrapper{
	float:left;
	width:100%;
}
.prs_mcc_left_searchbar_wrapper input{
	width:calc(100% - 50px);
	float:left;
	height:50px;
	padding-left:20px;
	background:#f8f8f8;
	border:0;
	    -webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.prs_mcc_left_searchbar_wrapper button{
	width:50px;
	height:50px;
	float:left;
	border:0;
	background:#ff4444;
	color:#ffffff;
	    -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_left_searchbar_wrapper button:hover{
	background:#111111;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_left_searchbar_wrapper button i:before{
	margin-left:0;
}
.prs_mcc_bro_title_wrapper{
	float:left;
	width:100%;
	padding-top:60px;
}
.prs_mcc_bro_title_wrapper h2{
	font-size:20px;
	color:#000000;
	text-transform:uppercase;
	font-weight:bold;
	position:relative;
}
.prs_mcc_bro_title_wrapper h2:after{
	content:'';
	border:1px solid #dddddd;
	width:150px;
	position:absolute;
	bottom:-20px;
	left:0;
}
.prs_mcc_bro_title_wrapper h2:before{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	bottom:-21px;
	left:0;
	z-index:1;
}
.prs_mcc_bro_title_wrapper ul{
	margin-top:45px;
}
.prs_mcc_bro_title_wrapper li{
	border-bottom:1px solid #e2e2e2;
	padding-bottom:10px;
	margin-top:10px;
}
.prs_mcc_bro_title_wrapper li:last-child{
	border-bottom:0;
}
.prs_mcc_bro_title_wrapper li i{
	color:#ff4444;
}
.prs_mcc_bro_title_wrapper li a span{
	float:right;
}
.prs_mcc_bro_title_wrapper li a{
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_bro_title_wrapper li a:hover{
	color:#ff4444;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_event_title_wrapper{
	float:left;
	width:100%;
	margin-top:55px;
}
.prs_mcc_event_title_wrapper h2{
	font-size:20px;
	color:#000000;
	text-transform:uppercase;
	font-weight:bold;
	position:relative;
}
.prs_mcc_event_title_wrapper h2:after{
	content:'';
	border:1px solid #dddddd;
	width:150px;
	position:absolute;
	bottom:-20px;
	left:0;
}
.prs_mcc_event_title_wrapper h2:before{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	bottom:-21px;
	left:0;
	z-index:1;
}
.prs_mcc_event_title_wrapper img{
	width:100%;
	margin-top:45px;
}
.prs_mcc_event_title_wrapper h3{
	font-size:18px;
	padding-top:30px;
	font-weight:bold;
	text-transform:uppercase;
}
.prs_mcc_event_title_wrapper h3 a{
	color:#000000;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_event_title_wrapper h3 a:hover{
	color:#ff4444;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_event_title_wrapper p{
	padding-top:10px;
	border-bottom:1px solid #e2e2e2;
	padding-bottom:15px;
}
.prs_mcc_event_title_wrapper p span{
	float:right;
}
.prs_mcc_event_title_wrapper p span i{
	color:#ff4444;
	font-size:14px;
}
.prs_mcc_event_title_wrapper h4{
	font-family: 'Lato', sans-serif;
	font-size:14px;
	color:#ff4444;
	padding-top:15px;
}
.prs_mcc_event_title_wrapper h4 span{
	float:right;
	color:#000000;
}
.prs_mcc_list_movie_main_wrapper{
	float:left;
	width:100%;
	margin-top:30px;
}
.prs_mcc_list_movie_img_wrapper{
	float:left;
	width:270px;
}
.prs_mcc_list_movie_img_cont_wrapper{
	float:left;
	width:calc(100% - 270px);
	background:#ffffff;
	min-height:360px;
	padding:50px;
	-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
	    -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_list_movie_img_wrapper img{
	width:100%;
	-webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.prs_mcc_list_movie_main_wrapper:hover .prs_mcc_list_movie_img_cont_wrapper{
	    -webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_list_left_cont_wrapper{
	float:left;
	width:80%;
}
.prs_mcc_list_right_cont_wrapper{
	float:left;
	width:20%;
}
.prs_mcc_list_left_cont_wrapper h2{
	font-size:18px;
	color:#000000;
	font-weight:bold;
	text-transform:uppercase;
}
.prs_mcc_list_left_cont_wrapper p{
	padding-top:10px;
}
.prs_mcc_list_left_cont_wrapper p i{
	color:#ff4444;
}
.prs_mcc_list_left_cont_wrapper h4{
	color:#ff4444;
	font-size:16px;
	font-family: 'Lato', sans-serif;
	padding-top:10px;
}
.prs_mcc_list_bottom_cont_wrapper{
	float:left;
	width:100%;
	padding-top:25px;
}
.prs_mcc_list_bottom_cont_wrapper ul{
	padding-top:30px;
}
.prs_mcc_list_bottom_cont_wrapper li{
	float:left;
	margin-right:20px;
}
.prs_mcc_list_bottom_cont_wrapper li:first-child a{
	float:left;
	width:170px;
	height:50px;
	text-align:center;
	line-height:50px;
	background:#ff4444;
	border:1px solid #ff4444;
	color:#ffffff;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_list_bottom_cont_wrapper li:first-child a:hover{
	background:transparent;
	color:#000000;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_list_bottom_cont_wrapper li:last-child a{
	float:left;
	width:170px;
	height:50px;
	text-align:center;
	line-height:50px;
	background:transparent;
	border:1px solid #ff4444;
	color:#000000;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_list_bottom_cont_wrapper li:last-child a:hover{
	background:#ff4444;
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_mcc_list_right_cont_wrapper a{
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    float: right;
    border: 1px solid #eeeeee;
    color: #ff4444;
	margin-top:10px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}	
.prs_mcc_list_right_cont_wrapper a i:before{
	margin-left:0;
}
.prs_mcc_list_right_cont_wrapper a:hover{
	background:#ff4444;
	color:#ffffff;
	 -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
/*-- prs mc slider wrapper End --*/
/************************----------------- MOVIE CATEGORY CSS End ----------------**************/
/************************----------------- MOVIE Single CSS Start ----------------**************/
/*-- prs ms trailer wrapper Start --*/
.prs_ms_trailer_vid_main_wrapper{
	float:left;
	width:100%;
	background:#f6f6f6;
	padding-top:94px;
	padding-bottom:94px;
}
.prs_ms_trailer_vid_wrapper{
	float:left;
	width:100%;
	background:url('../images/content/movie_single/vid_bg.jpg') 50% 0 repeat-y;
	height:100%;
	background-position:center 0;
	background-size:cover;
	background-repeat:no-repeat;
	position:relative;
	padding-top:170px;
	padding-bottom:170px;
	    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	margin-top:70px;
}
.prs_ms_trailer_vid_img_overlay{
	position:absolute;
	left:0%;
	right:0%;
	top:0%;
	bottom:0%;
	background:rgba(0,0,0,0.2);
	    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	
}
.prs_ms_trailer_vid_icon_wrapper{
	text-align:center;
	float:left;
	width:100%;
}
.prs_ms_trailer_vid_icon_wrapper ul{
	display:inline-block;
}
.prs_ms_trailer_vid_icon_wrapper li{
	border: 10px solid rgba(255, 255, 255, 0.29);
    float: left;
   color:#ffffff;
	 -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.prs_ms_trailer_vid_icon_wrapper li a{
	float:left;
	width:90px;
	height:90px;
	line-height:90px;
	text-align:center;
	background:#ff4444;
	color:#ffffff;
	    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
	animation-duration: 1s;
    animation-delay: 100ms;
    animation-iteration-count: infinite;
    animation-name: pulse;
}
.prs_ms_trailer_vid_icon_wrapper li a i:before{
	margin-left:0;
	font-size:35px;
	
}
.prs_ms_trailer_vid_icon_wrapper h2{
	font-size:32px;
	color:#ffffff;
	font-weight:bold;
	position:relative;
	text-transform:uppercase;
}
.prs_ms_trailer_slider_main_wrapper{
	float:left;
	width:100%;
	margin-top:60px;
}
.prs_ms_trailer_slider_left_wrapper{
	float:left;
	width:60%;
}
.prs_ms_trailer_slider_right_wrapper{
	float:left;
	width:40%;
	background:#fff;
	min-height:468px;
	    -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
	padding:80px;
	-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10); 
	 -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_trailer_slider_right_wrapper:hover{
	-webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	 -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_trailer_slider_left_wrapper  .owl-theme .owl-dots{
	 position: absolute;
    top: 90%;
    margin: 0;
    left: 0;
    right: 0;
	z-index:1;
	display:block;
}
.prs_ms_trailer_slider_left_wrapper  .owl-theme .owl-dots .owl-dot span{
	width:13px;
	height:13px;
	background:rgba(255, 255, 255, 0.37);
	 -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_trailer_slider_left_wrapper  .owl-theme .owl-dots .owl-dot.active span, .prs_ms_trailer_slider_left_wrapper  .owl-theme .owl-dots .owl-dot:hover span {
	background:rgba(255, 255, 255, 0.63);
	width:13px;
	height:13px;
	 -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_trailer_slider_left_wrapper  .owl-theme .owl-dots .owl-dot.active span:after, .prs_ms_trailer_slider_left_wrapper  .owl-theme .owl-dots .owl-dot:hover span:after{
	    content: '';
    border: 2px solid rgba(255, 255, 255, 0.77);
    width: 23px;
    height: 24px;
    border-radius: 100%;
    position: absolute;
   margin-left: -11px;
    margin-top: -5px;
	 -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_trailer_slider_left_wrapper .owl-theme .owl-nav {
    position: absolute;
    top: 45%;
    z-index: 1000;
    display: block;
	left:0;
	right:0;
	margin:0px auto;
}
.prs_ms_trailer_slider_left_wrapper .owl-theme .owl-nav .owl-prev{
    margin: 0;
    padding: 0;
    left: 15px;
	font-size: 26px;
	top:0;
	color:#ffffff;
	width:50px;
	height:50px;
	line-height: 47px;
    padding-left: 5px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.prs_ms_trailer_slider_left_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
    right: 15px;
	top:-5px;
    position: absolute;
	color:#ffffff;
	width:50px;
	padding-left: 11px;
    height: 50px;
    line-height: 37px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_trailer_slider_left_wrapper  .owl-theme .owl-nav .owl-next i:before,
.prs_ms_trailer_slider_left_wrapper .owl-theme .owl-nav .owl-prev i:before{
	margin-left:0;
}
.prs_ms_trailer_slider_left_img_wrapper img{
	-webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.prs_ms_trailer_slider_right_wrapper h2{
	font-size:32px;
	color:#000000;
	font-weight:bold;
	text-transform:uppercase;
}
.prs_ms_trailer_slider_right_wrapper h2 span{
	color:#ff4444;
}
.prs_ms_trailer_slider_right_wrapper p{
	text-transform:uppercase;
	font-size:18px;
	padding-top:25px;
}
.prs_ms_trailer_slider_right_wrapper img{
	width:100%;
	margin-top:25px;
}
.prs_ms_trailer_slider_right_wrapper h5{
	font-family: 'Lato', sans-serif;
	color:#797979;
	font-size:16px;
	padding-top:25px;
	line-height:26px;
}
.prs_ms_trailer_slider_right_wrapper h5 span{
	color:#000000;
}
.prs_ms_trailer_slider_right_wrapper li{
	margin-top:15px;
	font-size:18px;
	font-weight:bold;
	color:#000000;
}
.prs_ms_trailer_slider_right_wrapper li span{
	color:#ff4444;
}
.prs_movie_cast_main_slider_wrapper{
	float:left;
	width:100%;
	background:#f6f6f6;
	padding-bottom:94px;
}
.prs_movie_cast_heading_wrapper{
	float:left;
	width:100%;
	position:relative;
	text-align:center;
}
.prs_movie_cast_heading_wrapper h2{
	font-size:32px;
	color:#000000;
	text-transform:uppercase;
	font-weight:bold;
	position:relative;
}
.prs_movie_cast_heading_wrapper h2:after{
	content:'';
	border:1px solid rgba(0, 0, 0, 0.12);
	width:170px;
	position:absolute;
	bottom:-20px;
	left:0;
	right:0;
	margin:0px auto;
}
.prs_movie_cast_heading_wrapper h2:before{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	bottom:-21px;
	left:0;
	right:0;
	margin:0px auto;
	z-index:1;
}
.prs_ms_cast_slider_cont_wrapper{
	float:left;
	width:100%;
	    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
	-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_cast_slider_cont_wrapper:hover{
	-webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_cast_img_wrapper{
	float:left;
	width:100%;
}
.prs_ms_cast_img_wrapper img{
	width:100%;
	-webkit-border-top-left-radius: 10px;
		-moz-border-top-left-radius: 10px;
		border-top-left-radius: 10px;
		-webkit-border-top-right-radius: 10px;
		-moz-border-top-right-radius: 10px;
		border-top-right-radius: 10px;
}
.prs_ms_cast_img_cont_wrapper{
	float:left;
	width:100%;
	background:#ffffff;
	text-align:center;
	padding-top:25px;
	padding-bottom:25px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.prs_ms_cast_img_cont_wrapper h4{
	font-size:18px;
}
.prs_ms_cast_img_cont_wrapper h4 a{
	color:#000000;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_cast_img_cont_wrapper h4 a:hover{
	color:#ff4444;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_cast_img_cont_wrapper p{
	font-size:14px;
	padding-top:5px;
}
.prs_movie_cast_main_slider_wrapper .bx-viewport{
	height:400px !important;
}
/*-- prs ms trailer wrapper End --*/
/*-- prs syn Slider Start --*/
.prs_syn_main_section_wrapper{
	float:left;
	width:100%;
	background:#f1f1f1;
	padding-top:94px;
	padding-bottom:100px;
}
.prs_syn_cont_wrapper{
	float:left;
	width:100%;
}
.prs_syn_cont_wrapper h3{
	font-size:32px;
	color:#000000;
	font-weight:bold;
	text-transform:uppercase;
	position:relative;
}
.prs_syn_cont_wrapper h3:after{
	content:'';
	width:30px;
	border:2px solid #ff4444;
	position:absolute;
	left:0;
	bottom:-20px;
}
.prs_syn_cont_wrapper h3:before{
	content:'';
	width:90px;
	border:1px solid #e3e3e3;
	position:absolute;
	left:0;
	bottom:-20px;
}
.prs_syn_cont_wrapper h4{
	font-size:18px;
	color:#707070;
	padding-top:50px;
	font-family: 'Lato', sans-serif;
}
.prs_syn_cont_wrapper h4 span{
	color:#000000;
}
.prs_syn_cont_wrapper p{
	padding-top:30px;
}
.prs_syn_img_wrapper{
	float:left;
	width:100%;
}
.prs_syn_img_wrapper img{
	width:100%;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
/*-- prs syn Slider End --*/
/*-- prs ms scene slider Start --*/
.prs_ms_scene_main_wrapper{
	float:left;
	width:100%;
	padding-top:94px;
	padding-bottom:210px;
	background:#f1f1f1;
}
.prs_ms_scene_slider_img{
	float:left;
	width:100%;
	position:relative;
}
.prs_ms_scene_slider_wrapper{
	float:left;
	width:100%;
	margin-top:70px;
}
.prs_ms_scene_slider_img img{
	width:100%;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.prs_ms_scene_slider_wrapper  .owl-theme .owl-dots{
	display:none;
}
.prs_ms_scene_slider_wrapper .owl-theme .owl-nav {
    position: absolute;
    bottom:-59px;
    z-index: 1000;
    display: block;
	left:0;
	right:0;
	margin:0px auto;
}
.prs_ms_scene_slider_wrapper .owl-theme .owl-nav .owl-prev{
    margin: 0;
    padding: 0;
	font-size: 26px;
	top:0;
	margin-left:-55px;
	color:#ffffff;
	width:50px;
	height:50px;
	line-height: 47px;
    padding-left: 5px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.prs_ms_scene_slider_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
	top:-5px;
    position: absolute;
	color:#ffffff;
	width:50px;
	margin-left:2px;
	padding-left: 11px;
    height: 50px;
    line-height: 37px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_scene_slider_wrapper  .owl-theme .owl-nav .owl-next i:before,
.prs_ms_scene_slider_wrapper .owl-theme .owl-nav .owl-prev i:before{
	margin-left:0;
}
.prs_ms_scene_img_overlay:after{
	content:'';
	position:absolute;
	top:200px;
	bottom:200px;
	right:200px;
	left:200px;
	background:rgba(0,0,0,0.8);
	opacity:0;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.prs_ms_scene_slider_img:hover .prs_ms_scene_img_overlay:after{
	content:'';
	position:absolute;
	top:0;
	bottom:0;
	right:0;
	left:0;
	background:rgba(0,0,0,0.8);
	opacity:1;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.prs_ms_scene_img_overlay a{
	display:inline-block;
	line-height:40px;
	text-align:center;
	position:absolute;
	left:0;
	right:0;
	margin:0px auto;
	top:42%;
	font-size:14px;
	-webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
	z-index:10;
	opacity:0;
	    animation-duration: 1s;
    animation-delay: 100ms;
    animation-iteration-count: infinite;
    animation-name: pulse;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_scene_img_overlay a i:before{
	margin-left:0;
	color:#ffffff;
}
.prs_ms_scene_slider_img:hover .prs_ms_scene_img_overlay a{
	opacity:1;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_scene_slider_img2{
	margin-top:30px;
}
/*-- prs ms scene slider End --*/
/*-- prs ms crew Start --*/
.prs_ms_crew_main_wrapper{
	float:left;
	width:100%;
	background:#f6f6f6;
	padding-top:94px;
	padding-bottom:100px;
}
.prs_ms_crew_left_cont_wrapper{
	float:left;
	width:100%;
	background:#ffffff;
	padding:50px;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	margin-top:70px;
	-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);  
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_crew_left_cont_wrapper:hover{
	 -webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_crew_left_cont_wrapper li{
	margin-top:30px;
}
.prs_ms_crew_left_cont_wrapper li:first-child{
	margin-top:0;
}
.prs_ms_crew_left_cont_wrapper li span{
	float:right;
}
/*-- prs ms crew End --*/
/*-- prs related movie slider Start --*/
.prs_ms_rm_main_wrapper{
	float:left;
	width:100%;
	padding-top:100px;
	padding-bottom:90px;
	background:#f1f1f1;
}
.prs_ms_rm_slider_wrapper{
	float:left;
	width:100%;
	margin-top:70px;
}
.prs_ms_rm_slider_wrapper  .owl-theme .owl-dots{
	display:none;
}
.prs_ms_rm_slider_wrapper .owl-theme .owl-nav {
    position: absolute;
    top: 50%;
    z-index: 1000;
    display: block;
	left:0;
	right:0;
	margin:0px auto;
}
.prs_ms_rm_slider_wrapper .owl-theme .owl-nav .owl-prev{
    margin: 0;
    padding: 0;
    left: -75px;
	font-size: 26px;
	top:0;
	color:#ffffff;
	width:50px;
	height:50px;
	line-height:45px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.prs_ms_rm_slider_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
    right: -75px;
	top:-5px;
    position: absolute;
	color:#ffffff;
	width:50px;
	padding-left:14px;
	height:50px;
	line-height:40px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_rm_slider_wrapper  .owl-theme .owl-nav .owl-next i:before{
	margin-left:-3px;
}
.prs_ms_rm_slider_wrapper .owl-theme .owl-nav .owl-prev i:before{
	margin-left:5px;
}
/*-- prs related movie slider End --*/
/************************----------------- MOVIE Single CSS End ----------------**************/
/************************----------------- Event CATEGORY CSS Start ----------------**************/
/*-- prs ec ue wrapper Start --*/
.prs_ec_ue_main_section_wrapper{
	float:left;
	width:100%;
	padding-top:94px;
	padding-bottom:90px;
	background:#f6f6f6;
}
.prs_ec_ue_left_img_wrapper{
	float:left;
	width:50%;
	margin-top:80px;
}
.prs_ec_ue_right_img_wrapper{
	float:left;
	width:50%;
	margin-top:80px;
	background:#ffffff;
	min-height:446px;
	    -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
	padding:32px;
	    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ec_ue_right_img_wrapper:hover{
	 -webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ec_ue_left_img_wrapper img{
	width:100%;
	    -webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.prs_ec_ue_right_img_cont{
	border:0;
	padding:0;
	    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
}
.prs_ec_ue_right_img_cont .prs_up_pera_sec{
	padding-top:25px;
	display:inline-block;
}
.prs_ec_ue_right_img_cont .prs_up_pera_sec a{
	color:#ff4444;
	font-weight:bold;
}
.prs_ec_ue_timer_wrapper{
	float:left;
	width:100%;
	margin-top:40px;
}
#clockdiv{
	color: #fff;
	display: inline-block;
	font-weight: 100;
	text-align: center;
	font-size: 30px;
}
#clockdiv > div{
	padding: 0px;
	border-radius: 10px;
	background: transparent;
	display: inline-block;
	position:relative;
	margin-right:15px;
}
#clockdiv > div:last-child:after{
	display:none;
}
#clockdiv div > span{
    padding: 0px;
    display: inline-block;
    width: 75px;
    height: 75px;
    line-height: 75px;
    position: relative;
	    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
#clockdiv div:first-child > span{
	background:#43b9f6;
}
#clockdiv div:nth-child(2) > span{
	background:#6686ff;
}
#clockdiv div:nth-child(3) > span{
	background:#4cb3c4;
}
#clockdiv div:last-child > span{
	background:#85df88;
}
.smalltext{
	color:#797979;
	padding-top: 15px;
	font-size: 16px;
}
.prs_ec_ue_inner_feat_wrapper{
	margin-top:30px;
}
.prs_ec_pe_slider_main_wrapper{
	background:url('../images/content/event/event_bg.jpg') 50% 0 repeat-y;
	background-position:center 0;
	background-size:cover;
	background-repeat:no-repeat;
	width:100%;
	height:100%;
	float:left;
	position:relative;
	padding-top:94px;
	padding-bottom:94px;
}
.prs_ec_pe_slider_img_overlay{
	position:absolute;
	left:0%;
	right:0%;
	bottom:0%;
	top:0%;
	background:rgba(0,0,0,0.8);
}
.prs_ec_pe_heading_section_wrapper{
	float:left;
	width:100%;
	text-align:center;
}
.prs_ec_pe_heading_section_wrapper h2{
	font-size:32px;
	color:#ffffff;
	text-transform:uppercase;
	font-weight:bold;
	position:relative;
}
.prs_ec_pe_heading_section_wrapper h2:after{
	content:'';
	border:1px solid rgba(255, 255, 255, 0.2784313725490196);
	width:170px;
	position:absolute;
	bottom:-20px;
	left:0;
	right:0;
	margin:0px auto;
}
.prs_ec_pe_heading_section_wrapper h2:before{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	bottom:-21px;
	left:0;
	right:0;
	margin:0px auto;
	z-index:1;
}
/*-- prs ec ue wrapper End --*/
/************************----------------- Event CATEGORY CSS End ----------------**************/
/************************----------------- Gallery CSS Start ----------------**************/
.prs_gallery_main_section_wrapper{
	float:left;
	width:100%;
	background:#f6f6f6;
	padding-top:94px;
	padding-bottom:94px;
}
#filter{
	display:inline-block;
	margin-top:70px;
	background:#ffffff;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
#filter li a{
    display: block;
    font-size: 16px;
    cursor: pointer;
	color:#000000;
	font-weight:500;
	background:transparent;
    padding: 5px 10px;
    margin-right: 10px;
	position:relative;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
#filter li:nth-child(2){
	border-right:1px solid #e2e2e2;
	border-left:1px solid #e2e2e2;
}
#filter li a:hover,
#filter li a.active{
	background:#ff4444;
	color:#ffffff;
}
#filter li:first-child a:hover,
#filter li:first-child a.active{
	    -webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
#filter li:last-child a:hover,
#filter li:last-child a.active{
	    -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
#filter li a:last-child{
    margin-right: 0px;
}
.three-column{
	margin-top:25px;
}
.III_column{
	margin-top:30px;
}
.list-inline > li{
	padding: 0;
	float:left;
}
#filter li a{
	padding: 15px 70px;	
}
.prs_gallery_btn_wrapper ul{
	margin-top:60px;
}
/************************----------------- Gallery CSS End ----------------**************/
/************************----------------- Contact CSS Start ----------------**************/
.prs_contact_form_main_wrapper{
	float:left;
	width:100%;
	background:#f6f6f6;
	padding-top:100px;
	padding-bottom:100px;
}
.prs_contact_left_wrapper{
	float:left;
	width:100%;
	padding-bottom:47px;
	margin-top: -6px;
}
.prs_contact_left_wrapper h2{
	font-size:32px;
	color:#000000;
	font-weight:bold;
	position:relative;
	text-transform:uppercase;
}
.prs_contact_left_wrapper h2:after{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	left:0;
	bottom:-20px;
}
.prs_contact_left_wrapper h2:before{
	content:'';
	border:1px solid #e2e2e2;
	width:90px;
	position:absolute;
	left:0;
	bottom:-19px;
}
.prs_contact_input_wrapper{
	float:left;
	width:100%;
	margin-top:32px;
}
.prs_contact_input_wrapper2{
	margin-top:27px;
}
.prs_contact_input_wrapper input{
	width:100%;
	height:50px;
	background:#ffffff;
	-webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
	border:0;
	padding-left:20px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_contact_input_wrapper textarea{
	width:100%;
	border:0;
	padding-left:20px;
	padding-top:20px;
	resize:none;
	-webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_contact_input_wrapper input:hover,.prs_contact_input_wrapper input:focus,
.prs_contact_input_wrapper textarea:hover,.prs_contact_input_wrapper textarea:focus{
	-webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_contact_input_wrapper li a{
	float:left;
	width:170px;
	height:50px;
	text-align:center;
	line-height:48px;
	background:#ff4444;
	color:#ffffff;
	border:1px solid transparent;
	    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.prs_contact_input_wrapper li a:hover{
	background:transparent;
	color:#ff4444;
	border:1px solid #ff4444;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.prs_contact_right_section_wrapper{
	float:left;
	width:100%;
	background:#ff4444;
	    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	padding-top:100px;
	padding-bottom:100px;
	margin-top:20px;
	position:relative;
	text-align:center;
}
.prs_contact_right_section_wrapper:after{
	content:'';
	width:80%;
	height:20px;
	background:#e74343;
	position:absolute;
	top:-20px;
	left:0;
	right:0;
	margin:0px auto;
	    -webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
	    -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
}
.prs_contact_right_section_wrapper ul{
	display:inline-block;
}
.prs_contact_right_section_wrapper li{
	text-align:left;
	margin-top:20px;
}
.prs_contact_right_section_wrapper li:first-child{
	margin-top:0;
}
.prs_contact_right_section_wrapper li a{
	color:#ffffff;
}
.prs_contact_info_main_wrapper{
	float:left;
	width:100%;
	padding-top:100px;
	padding-bottom: 95px;
	background:#f6f6f6;
}
.prs_contact_info_box_wrapper{
	float:left;
	width:100%;
	text-align:center;
}
.prs_contact_info_smallbox{
	display:inline-block;
	width:75px;
	height:75px;
	text-align:center;
	line-height:75px;
	background:#ff4444;
	color:#ffffff;
	position:relative;
	margin-top:10px;
	-webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.prs_contact_info_smallbox:after{
	content:'';
	width:80%;
	height:10px;
	background:#e74343;
	position:absolute;
	top:-10px;
	left:0;
	right:0;
	margin:0px auto;
	-webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
}
.prs_contact_info_smallbox i:before{
	margin-left:0;
}
.prs_contact_info_box_wrapper h3{
	font-size:18px;
	color:#000000;
	font-weight:bold;
	margin-top:30px;
}
.prs_contact_info_box_wrapper p{
	padding-top:10px;
}
.prs_contact_info_box_wrapper p a{
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.prs_contact_info_box_wrapper p a:hover{
	color:#ff4444;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
/************************----------------- Contact CSS End ----------------**************/
/**************------- BLOG CATEGORIES CSS START ---------*******************/
.hs_blog_categories_main_wrapper{
	float:left;
	width:100%;
	padding-top:100px;
	padding-bottom:95px;
	background:#f6f6f6;
}
.hs_blog_categories_main_wrapper2{
	padding-bottom:100px;
}
.hs_blog_left_sidebar_main_wrapper{
	float:left;
	width:100%;
}
.hs_blog_right_sidebar_main_wrapper{
	float:left;
	width:100%;
	background:#ffffff;
	padding:40px 30px;
	    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.hs_blog_right_sidebar_main_wrapper:hover{
	-webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.hs_blog_box1_main_wrapper{
	float:left;
	width:100%;
}
.hs_blog_box1_img_wrapper img{
	width:100%;
	    -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
}
.hs_blog_box1_img_wrapper{
	position:relative;
	float:left;
	width:100%;
	overflow:hidden;
}
.hs_blog_box1_img_wrapper iframe{
	width:100%;
	height:350px;
	border:0;
}
.hs_blog_box1_cont_main_wrapper{
	float:left;
	width:100%;
	border-bottom:1px solid #e2e2e2;
	padding:35px;
	background:#ffffff;
	    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.hs_blog_box1_main_wrapper:hover .hs_blog_box1_cont_main_wrapper{
	-webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.hs_blog_cont_heading_wrapper h2{
	font-size:18px;
	color:#000000;
	font-weight:600;
	text-transform:uppercase;
	line-height:28px;
}
.hs_blog_cont_heading_wrapper p{
	padding-top:20px;
}
.hs_blog_cont_heading_wrapper h5 a{
	font-size:16px;
	color:#ff4444;
	padding-top:20px;
	display:inline-block;
}
.hs_blog_cont_heading_wrapper h5 a i{
	position:relative;
	top:2px;
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_blog_cont_heading_wrapper h5 a:hover i{
	padding-left:5px;
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_blog_cont_heading_wrapper ul{
	display:block;
	width:100%;
	float:left;
	padding-bottom:25px;
}
.hs_blog_cont_heading_wrapper li{
	float:left;
	margin-left:25px;
	color:#ff4444;
}
.hs_blog_cont_heading_wrapper li:first-child{
	margin-left:0;
	position:relative;
}
.hs_blog_cont_heading_wrapper li:first-child:after{
	content: '';
    border: 1px solid #ff4444;
    height: 15px;
    position: absolute;
    right: -13px;
    top: 5px;
}
.hs_blog_box1_bottom_cont_main_wrapper{
	float:left;
	width:100%;
	background:#ffffff;
	-webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
	    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
}
.hs_blog_box1_bottom_cont_left{
	float:left;
	width:70%;
	padding-left:35px;
}
.hs_blog_box1_bottom_cont_right{
	float:left;
	width:30%;
	padding-right:35px;
}
.hs_blog_box1_bottom_cont_left li{
	float:left;
	margin-right:30px;
	padding-top:15px;
	padding-bottom:15px;
}
.hs_blog_box1_bottom_cont_left li a, .hs_blog_box1_bottom_cont_right li a{
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_blog_box1_bottom_cont_left li a:hover, .hs_blog_box1_bottom_cont_right li a:hover{
	color:#ff4444;
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_blog_box1_bottom_cont_left li:last-child:after{
	display:none;
}
.hs_blog_box1_bottom_cont_right ul{
	float:right;
}
.hs_blog_box1_bottom_cont_right li{
	float:left;
	margin-right:10px;
	padding-top:15px;
	padding-bottom:15px;
}
.hs_blog_box2_main_wrapper{
	margin-top:60px;
}
.hs_blog_box1_img_wrapper{
	float:left;
	width:100%;
	position:relative;
}
.hs_blog_box1_img_wrapper  .owl-theme .owl-dots{
	display:none;
}
.hs_blog_box1_img_wrapper .owl-theme .owl-nav {
    position: absolute;
    margin: 0px auto;
    left: 0;
    top: 45%;
    right: 0;
	z-index:1000;
	display:block;
}
.hs_blog_box1_img_wrapper .owl-theme .owl-nav .owl-prev{
    margin: 0;
    padding: 0;
    left: 20px;
	font-size: 26px;
	top:0;
	color:#ffffff;
	width:50px;
	height:50px;
	line-height:49px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:rgba(255, 66, 66, 0.64);
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.hs_blog_box1_img_wrapper .owl-theme .owl-nav .owl-prev i:before, .hs_blog_box1_img_wrapper  .owl-theme .owl-nav .owl-next i:before{
	font-size:24px;
	margin-left:0;
}
.hs_blog_box1_img_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
    right: 20px;
	top:-5px;
    position: absolute;
	color:#ffffff;
	width:50px;
	padding-left:14px;
	height:50px;
	line-height:40px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:rgba(255, 66, 66, 0.64);
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_blog_box1_img_wrapper .owl-theme .owl-nav .owl-prev:hover, .hs_blog_box1_img_wrapper  .owl-theme .owl-nav .owl-next:hover{
	background:#ff4444;
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_blog_box1_bottom_cont_left li i{
	color:#ff4444;
}
.prs_ms_scene_img_overlay:after{
	content:'';
	position:absolute;
	top:200px;
	bottom:200px;
	right:200px;
	left:200px;
	background:rgba(0,0,0,0.8);
	opacity:0;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.hs_blog_box1_main_wrapper:hover .prs_ms_scene_img_overlay:after{
	content:'';
	position:absolute;
	top:0;
	bottom:0;
	right:0;
	left:0;
	background:rgba(0,0,0,0.8);
	opacity:1;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.prs_ms_scene_img_overlay a{
	display:inline-block;
	line-height:40px;
	text-align:center;
	position:absolute;
	left:0;
	right:0;
	margin:0px auto;
	top:42%;
	font-size:14px;
	-webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
	z-index:10;
	opacity:0;
	    animation-duration: 1s;
    animation-delay: 100ms;
    animation-iteration-count: infinite;
    animation-name: pulse;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_scene_img_overlay a i:before{
	margin-left:0;
	color:#ffffff;
}
.hs_blog_box1_main_wrapper:hover .prs_ms_scene_img_overlay a{
	opacity:1;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_ms_scene_img_overlay ul{
	display:inline-block;
	    position: absolute;
    left: 0;
    right: 0;
    margin: 0px auto;
    top: 40%;
}
.prs_ms_scene_img_overlay li{
	border: 10px solid rgba(255, 255, 255, 0.29);
    float: left;
   color:#ffffff;
	 -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.prs_ms_scene_img_overlay li a{
	float:left;
	width:90px;
	height:90px;
	line-height:90px;
	text-align:center;
	background:#ff4444;
	color:#ffffff;
	    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
	animation-duration: 1s;
    animation-delay: 100ms;
    animation-iteration-count: infinite;
    animation-name: pulse;
}
.prs_ms_scene_img_overlay li a i:before{
	margin-left:0;
	font-size:35px;
}
.prs_blog_pagi_wrapper ul{
	padding-top:55px;
}	
.pagination > li > a{
	margin-top:5px !important;
}
.prs_bc_right_about_wrapper{
	float:left;
	width:100%;
}
.prs_bc_right_about_wrapper img{
	width:100%;
	margin-top:40px;
}
.prs_bc_right_about_wrapper h2{
	font-size:20px;
	color:#000000;
	text-transform:uppercase;
	font-weight:bold;
	position:relative;
	margin-top:30px;
}
.prs_bc_right_about_wrapper h2:after{
	content:'';
	border:1px solid #dddddd;
	width:150px;
	position:absolute;
	bottom:-20px;
	left:0;
}
.prs_bc_right_about_wrapper h2:before{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	bottom:-21px;
	left:0;
	z-index:1;
}
.prs_bc_right_about_wrapper p{
	padding-top:35px;
}
.prs_bc_right_about_wrapper h5 a{
	font-size:16px;
	color:#ff4444;
	padding-top:15px;
	display:inline-block;
	font-family: 'Lato', sans-serif;
}
.prs_bc_right_about_wrapper h5 a i{
	position:relative;
	top:2px;
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_bc_right_about_wrapper h5 a:hover i{
	padding-left:5px;
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_blog_right_recnt_cont_wrapper{
	float:left;
	width:100%;
	margin-top:45px;
}
.hs_blog_right_recnt_cont_wrapper2{
	margin-top:20px;
}
.hs_footer_ln_img_wrapper{
	float:left;
	width:80px;
}
.hs_footer_ln_cont_wrapper{
	float:left;
	width:calc(100% - 80px);
	    padding-left: 10px;
    padding-top: 7px;
}
.hs_footer_ln_cont_wrapper h4{
	font-size:16px;
	color:#000000;
	line-height:22px;
}
.hs_footer_ln_cont_wrapper p{
	font-size:16px;
	color:#ff4444;
}
.prs_blog_right_sub_btn_wrapper input{
	width:100%;
	height:50px;
	border:1px solid #e1e1e1;
	padding-left:20px;
	margin-top:50px;
}
.prs_blog_right_sub_btn_wrapper{
	float:left;
	width:100%;
	padding-top:60px;
}
.prs_blog_right_sub_btn_wrapper h2{
	font-size:20px;
	color:#000000;
	text-transform:uppercase;
	font-weight:bold;
	position:relative;
}
.prs_blog_right_sub_btn_wrapper h2:after{
	content:'';
	border:1px solid #dddddd;
	width:150px;
	position:absolute;
	bottom:-20px;
	left:0;
}
.prs_blog_right_sub_btn_wrapper h2:before{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	bottom:-21px;
	left:0;
	z-index:1;
}
.prs_blog_right_sub_btn_wrapper li a{
	float:left;
    width: 100%;
    height: 50px;
	margin-top:10px;
    text-align: center;
    line-height: 50px;
    background: #ff4444;
    border: 1px solid #ff4444;
    color: #ffffff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_blog_right_sub_btn_wrapper li a:hover{
	background: transparent;
    border: 1px solid #ff4444;
	color:#ff4444;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_bs_three_img_sec_wrapper{
	float:left;
	width:100%;
	margin-top:25px;
}
.prs_bs_three_img img{
	width:100%;
	margin-top:20px;
}
.prs_blog_pera{
	margin-top:30px;
	width:100%;
	float:left;
}
.prs_bs_admin_section_wrapper{
	float:left;
	width:100%;
	margin-top:40px;
}
.prs_bs_admin_cont_wrapper{
	float:left;
	width:calc(100% - 140px);
	padding:30px;
	border:1px solid #dddddd;
	min-height:150px;
	padding-top: 40px;
}
.prs_bs_admin_img_cont_wrapper{
	float:left;
	width:140px;
}
.prs_bs_admin_img_cont_wrapper img{
	width:100%;
}
.prs_bs_admin_cont_wrapper p{
	font-style:italic;
	color:#000000;
}
.prs_bs_admin_cont_wrapper h5{
	font-size:16px;
	color:#ff4444;
	font-weight:bold;
	font-family: 'Lato', sans-serif;
	margin-top:10px;
}
.prs_bs_admin_pera_last_wrapper{
	margin-top:40px;
	float:left;
	width:100%;
}
.prs_bs_about_admin_main_wrapper{
	float:left;
	width:100%;
	margin-top:60px;
}
.prs_bs_about_img_wrapper{
	float:left;
	width:140px;
}
.prs_bs_about_img_wrapper img{
	-webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.prs_bs_about_img_cont_wrapper{
	float:left;
	width:calc(100% - 140px);
	min-height:160px;
	background:#ff4444;
	padding:30px;
	    -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.prs_bs_about_img_cont_wrapper h4{
	font-size:20px;
	color:#ffffff;
	text-transform:uppercase;
	font-weight:bold;
	margin-top:10px;
}
.prs_bs_about_img_cont_wrapper p{
	color:#ffffff;
	margin-top:15px;
}
.prs_bs_comment_heading_wrapper{
	float:left;
	width:100%;
	padding-top:54px;
}
.prs_bs_comment_heading_wrapper h2{
	font-size:32px;
	color:#000000;
	text-transform:uppercase;
	font-weight:bold;
	position:relative;
}
.prs_bs_comment_heading_wrapper h2:after{
	content:'';
	border:1px solid #dddddd;
	width:150px;
	position:absolute;
	bottom:-20px;
	left:0;
}
.prs_bs_comment_heading_wrapper h2:before{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	bottom:-21px;
	left:0;
	z-index:1;
}
.hs_rs_comment_img_wrapper{
	float:left;
	width:110px;
}
.hs_rs_comment_img_cont_wrapper{
	float:left;
	width:calc(100% - 110px);
	padding-left:20px;
}
.hs_rs_comment_img_cont_wrapper h2{
	font-size:18px;
	font-weight:bold;
	color:#000000;
	line-height:26px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_rs_comment_img_cont_wrapper h2 span{
	font-family: 'Lato', sans-serif;
	font-size:14px;
	color:#ff4444;
}
.hs_rs_comment_img_cont_wrapper h2 a{
	font-family: 'Lato', sans-serif;
	font-size:14px;
	color:#ff4444;
}
.hs_rs_comment_img_cont_wrapper p{
	padding-top:10px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_rs_comment_img_cont_wrapper h5 a{
	font-size:16px;
	color:#ff7e00;
	padding-top:10px;
	float:left;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_rs_comment_img_cont_wrapper h5 a i{
	position:relative;
	top:2px;
	color:#ff7e00;
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_rs_comment_img_cont_wrapper h5 a:hover i{
	padding-left:5px;
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_rs_comment_main_wrapper{
	float:left;
	width:100%;
	padding:20px;
	background:#ffffff;
	margin-top:30px;
	-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_rs_comment_main_wrapper:hover{
	-webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_rs_comment_main_wrapper2{
	margin-top:80px;
	float:left;
	width:100%;
}
.hs_kd_six_sec_input_wrapper{
	float:left;
	width:100%;
	margin-top:30px;
}
.hs_kd_six_sec_input_wrapper input{
	width:100%;
	height:50px;
	border:0;
	padding-left:20px;
	padding-right:20px;
	-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_kd_six_sec_input_wrapper textarea{
	width:100%;
	border:0;
	padding:20px;
	resize:none;
	-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_kd_six_sec_input_wrapper input:hover,.hs_kd_six_sec_input_wrapper input:focus,.hs_kd_six_sec_input_wrapper textarea:hover,.hs_kd_six_sec_input_wrapper textarea:focus{
	-webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_kd_six_sec_btn{
	float:left;
	width:100px;
	margin-top:25px;
}
.hs_kd_six_sec_btn li a {
    float: left;
    width: 170px;
    height: 50px;
	font-weight:bold;
    background: #ff4444;
    color: #ffffff;
    text-align: center;
    line-height: 48px;
	letter-spacing:1px;
    border: 1px solid transparent;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_kd_six_sec_btn li a:hover {
    background: transparent;
    border: 1px solid #ff4444;
    color: #ff4444;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_bs_leave_comment_heading_wrapper{
	padding-bottom:50px;
}
/**************------- BLOG CATEGORIES CSS End ---------*******************/
/**************------- EVENT SINGLE CSS Start ---------*******************/
.prs_es_about_main_section_wrapper{
	float:left;
	width:100%;
	padding-top:100px;
	padding-bottom:100px;
	background:#f6f6f6;
}
.prs_es_about_left_wrapper{
	float:left;
	width:100%;
}
.prs_es_about_left_wrapper h2{
	font-size:32px;
	color:#000000;
	text-transform:uppercase;
	font-weight:bold;
	position:relative;
	margin-top: -6px;
}
.prs_es_about_left_wrapper h2:after{
	content:'';
	border:1px solid #dddddd;
	width:150px;
	position:absolute;
	bottom:-20px;
	left:0;
}
.prs_es_about_left_wrapper h2:before{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	bottom:-21px;
	left:0;
	z-index:1;
}
.prs_es_about_left_wrapper h4{
	font-size:18px;
	color:#000000;
	margin-top:60px;
	line-height:28px;
	font-weight:500;
}
.prs_es_about_left_wrapper p{
	padding-top:40px;
}
.prs_es_left_map_section_wrapper{
	float:left;
	background:url('../images/content/event/map_img.jpg') 50% 0 repeat-y;
	background-position:center 0;
	background-size:cover;
	background-repeat:no-repeat;
	width:60%;
	height:100%;
	padding-top:94px;
	padding-bottom:100px;
	padding-left:385px;
}
.prs_es_left_map_section_wrapper h2{
	font-size:32px;
	text-transform:uppercase;
	color:#ffffff;
	font-weight:bold;
}
.prs_es_left_map_section_wrapper h3{
	font-size:46px;
	text-transform:uppercase;
	color:#ff4444;
	font-weight:bold;
	padding-top:10px;
}
.prs_es_left_map_section_wrapper p{
	color:#ffffff;
	padding-top:10px;
}
.prs_es_right_map_section_wrapper{
	float:left;
	width:40%;
}
.prs_es_schedule_main_section_wrapper{
	float:left;
	width:100%;
	padding-top:94px;
	padding-bottom:100px;
	background:#f6f6f6;
}
.prs_es_tabs_wrapper{
	float:left;
	width:100%;
	text-align:center;
}
.prs_es_tabs_wrapper ul{
	display:inline-block;
	margin-top:80px;
}
.prs_es_tabs_cont_main_wrapper{
	float:left;
	width:100%;
	margin-top:55px;
}
.prs_es_tabs_wrapper .nav > li > a{
	padding: 25px 65px;
	font-family: 'Poppins', sans-serif;
	font-size:18px;
	text-transform:uppercase;
	font-weight:600;
	background:#ffffff;
	    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.prs_es_tabs_wrapper .nav-pills > li + li {
    margin-left: 20px;
}
.prs_es_tabs_wrapper .nav > li.active > a{
	background:#ff4444;
}
.prs_es_tabs_wrapper .nav > li > a span{
	font-family: 'Lato', sans-serif;
	font-size:16px;
	text-transform:capitalize;
	font-weight:100;
}
.prs_es_tabs_event_sche_main_box_wrapper{
	float:left;
	width:100%;
}
.prs_es_tabs_event_sche_img_wrapper{
	float:left;
	width:170px;
}
.prs_es_tabs_event_sche_img_wrapper img{
	width:100%;
	height: 219px;
	-webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.prs_es_tabs_event_sche_img_cont_wrapper{
	float:left;
	width:calc(100% - 170px);
	min-height:206px;
	background:#ffffff;
	padding:40px;
	    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
	-webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_es_tabs_event_sche_main_box_wrapper:hover .prs_es_tabs_event_sche_img_cont_wrapper{
	 -webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_es_tabs_event_sche_img_cont_wrapper h2{
	font-size:20px;
	color:#000000;
	text-transform:uppercase;
	font-weight:600;
}
.prs_es_tabs_event_sche_img_cont_wrapper h3{
	font-family: 'Lato', sans-serif;
	color:#ff4444;
	font-size:16px;
	padding-top:10px;
}
.prs_es_tabs_event_sche_img_cont_wrapper h3 span{
	color:#000000;
}
.prs_es_tabs_event_sche_img_cont_wrapper p{
	margin-top:10px;
}
.prs_es_tabs_event_sche_img_cont_wrapper li{
	float:left;
	margin-top:10px;
}
.prs_es_tabs_event_sche_img_cont_wrapper li:last-child{
	margin-left:30px;
}
.prs_es_tabs_event_sche_img_cont_wrapper li i{
	color:#ff4444;
}
.prs_es_tabs_event_sche_img_cont_wrapper li a{
	color:#000000;
	 -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_es_tabs_event_sche_img_cont_wrapper li a:hover{
	color:#ff4444;
	 -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_es_tabs_event_sche_main_box_wrapper2{
	margin-top:30px;
}
.prs_es_evnt_sche_btn_wrapper{
	text-align:center;
	float:left;
	width:100%;
}
.prs_es_evnt_sche_btn_wrapper ul{
	display:inline-block;
	margin-top:34px;
}
.prs_es_evnt_sche_btn_wrapper li a{
	width:200px;
}
.prs_es_speak_slider_main_Wrapper{
	float:left;
	width:100%;
	padding-top:95px;
	padding-bottom:100px;
	background:#f1f1f1;
}
.prs_es_speak_slider_wrapper  .owl-theme .owl-dots{
	display:none;
}
.prs_es_speak_slider_wrapper .owl-theme .owl-nav {
    position: absolute;
    top: 50%;
    z-index: 1000;
    display: block;
	left:0;
	right:0;
	margin:0px auto;
}
.prs_es_speak_slider_wrapper .owl-theme .owl-nav .owl-prev{
    margin: 0;
    padding: 0;
    left: -75px;
	font-size: 26px;
	top:0;
	color:#ffffff;
	width:50px;
	height:50px;
	line-height:45px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	-moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.prs_es_speak_slider_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
    right: -75px;
	top:-5px;
    position: absolute;
	color:#ffffff;
	width:50px;
	padding-left:14px;
	height:50px;
	line-height:40px;
	-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	background:#ff4444;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_es_speak_slider_wrapper  .owl-theme .owl-nav .owl-next i:before{
	margin-left:-3px;
}
.prs_es_speak_slider_wrapper .owl-theme .owl-nav .owl-prev i:before{
	margin-left:5px;
}
.prs_es_faq_main_wrapper{
	float:left;
	width:100%;
	padding-top:95px;
	padding-bottom:100px;
	background:#f6f6f6;
}
.prs_es_faq_left_wrapper{
	float:left;
	width:100%;
	background:#ff4444;
	    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
	padding:50px;
}
.prs_es_faq_left_wrapper h2{
	font-size:20px;
	text-transform:uppercase;
	color:#ffffff;
	position:relative;
	text-align:center;
}
.prs_es_faq_left_wrapper h2:after{
	content:'';
	border:2px solid #ffffff;
	width:30px;
	position:absolute;
	left:0;
	right:0;
	margin:0px auto;
	bottom:-20px;
}
.prs_es_faq_left_wrapper input{
	width:100%;
	height:50px;
	padding-left:20px;
	border:1px solid  rgba(255, 255, 255, 0.51);
	background:transparent;
	margin-top:20px;
	color:#ffffff;
}
.first_input{
	margin-top:60px !important;
}
.prs_es_faq_left_wrapper input::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.52);
}
.prs_es_faq_left_wrapper input::-moz-placeholder {
	color: rgba(255, 255, 255, 0.52);
}
.prs_es_faq_left_wrapper input:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.52);
}
.prs_es_faq_left_wrapper input:-moz-placeholder {
	color: rgba(255, 255, 255, 0.52);
}
.prs_es_faq_left_wrapper textarea{
	padding-left:20px;
	padding-top:20px;
	border:1px solid rgba(255, 255, 255, 0.51);
	width:100%;
	background:transparent;
	color:#ffffff;
	margin-top:20px;
}
.prs_es_faq_left_wrapper textarea::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.52);
}
.prs_es_faq_left_wrapper textarea::-moz-placeholder {
	color: rgba(255, 255, 255, 0.52);
}
.prs_es_faq_left_wrapper textarea:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.52);
}
.prs_es_faq_left_wrapper textarea:-moz-placeholder {
	color: rgba(255, 255, 255, 0.52);
}
.prs_es_faq_btn_wrapper{
	text-align:center;
	width:100%;
	float:left;
}
.prs_es_faq_btn_wrapper ul{
	display:inline-block;
}
.prs_es_faq_btn_wrapper li a{
	background:#ffffff;
	color:#000000;
	letter-spacing:0;
}
.prs_es_faq_btn_wrapper li a:hover{
	border:1px solid #ffffff;
	color:#ffffff;
}
.prs_es_faq_right_wrapper{
	float:left;
	width:100%;
}
.prs_es_faq_right_wrapper h2{
	font-size:32px;
	text-transform:uppercase;
	color:#000000;
	position:relative;
	font-weight:bold;
}
.prs_es_faq_right_wrapper h2:after{
	content:'';
	border:2px solid #ff4444;
	width:30px;
	position:absolute;
	left:0;
	bottom:-20px;
}
.accordionFifteen .panel-default {
  border: 0;
}
.accordionFifteen .panel {
	background-color: transparent;
	box-shadow: none;
	border-radius: 0;
	border: none;
	margin: 0;
}
.accordionFifteen .panel .panel-heading a {	
	display: block;
	background: white;
	padding: 15px 20px;
	color: #000;
	font-size:16px;
	font-weight:500;
    border-bottom: none;
	text-decoration:none;
	margin-top:15px;
}
.panel-heading desktop h4{
	line-height:0;
}
.panel-title h4{
	margin-left:-1px;
}
.accordionFifteen{
	margin-top:60px;
}
.accordionFifteen .panel .panel-heading a.collapsed {
	color: #000000;
	background-color:#fff;
	display: block;
	padding: 20px 20px;
	font-size:16px;
	font-weight:500;
	margin-top:15px;
	-webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.accordionFifteen .panel-default>.panel-heading {
  border-radius: 0px;
  padding:0px;
  margin-left:-1px;
  margin-top:-1px;
     background: transparent;
}
.accordionFifteen .panel-group .panel-heading+.panel-collapse>.panel-body {
  border-top: 0;
  padding-top: 0;
  padding: 30px 30px 30px 20px;
  background: #fff;
  border-top:none;
  margin-left:-1px;
  margin-top:-1px;
  -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.accordionFifteen .panel .panel-heading a:before,
.accordionFifteen .panel .panel-heading a.collapsed:before{
    font-family: 'FontAwesome';
    font-size: 16px;
    float: left;
    margin-right: 60px;
    margin-left: 20px;
    display: block;
}
.accordionFifteen .panel .panel-heading a:after,
.accordionFifteen .panel .panel-heading a.collapsed:after{
	font-family: 'FontAwesome';
	font-size: 24px;
	float: right;
	width: 50px;
	height: 25px;
	line-height: 24px;
	text-align: center;
	display: block;
	border-radius: 50%;	
}
.accordionFifteen .panel .panel-heading a:after {  
  content: "\f107";
  background: #ff4444;
  width:24px;
  height:24px;
  border-radius:100%;
  color:white;
  font-size:16px;
}
.accordionFifteen .panel .panel-heading a.collapsed:after {
	content: "\f105";
	background: #f4f4f4;
	  width:24px;
	  height:24px;
	  border-radius:100%;
	  color:#000000;
	 font-size:16px;
     padding-left: 3px;
}
.prs_es_pricing_table_main_wrapper{
	float:left;
	width:100%;
	padding-top:94px;
	padding-bottom:100px;
	background:#f1f1f1;
}
.prs_es_pt_box_wrapper{
	color: #000;
	float:left;
	width:100%;
	/* background:#4f93ff; */
	padding-top:95px;
	padding-bottom:40px;
	    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 15px;
	position:relative;
	margin-top:100px;
	    -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10);
	text-align:center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	border: #ff4444 2px solid;
}
.prs_es_pt_box_wrapper:hover{
	background: #c4c4c4;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.prs_es_pt_heading_box_wrapper{
	width:185px;
	height:75px;
	line-height:75px;
	background:#ff4444;
	text-align:center;
	    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
	position:absolute;
	top:-20px;
	left:0;
	right:0;
	margin:0px auto;
}
.prs_es_pt_heading_box_wrapper:after{
	content:'';
	width:80%;
	height:10px;
	    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
	    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
	position:absolute;
	top:-10px;
	left:0;
	right:0;
	margin:0px auto;
	background:#d63f3f;
	
}
.prs_es_pt_heading_box_wrapper h2{
	font-size:24px;
	font-weight:bold;
	color:#ffffff;
	text-transform:uppercase;
	line-height:75px;
}
.prs_es_price_wrapper{
	float:left;
	width:100%;
	background:#ffffff;
	text-align:center;
	padding-top:20px;
	padding-bottom:20px;
}
.prs_es_price_wrapper h3{
	font-size:40px;
	color:#000000;
	font-weight:bold;
}
.prs_es_price_wrapper h3 span{
	font-size:20px;
	    position: relative;
    top: -13px;
}
.prs_es_pt_box_wrapper ul{
	display:inline-block;
}
.prs_es_pt_box_wrapper li{
	font-size:14px;
	color:#000;
	margin-top:30px;
	position:relative;
}
.prs_es_pt_box_wrapper li:after{
	content:'';
	position:absolute;
	border:1px solid rgba(255, 255, 255, 0.22);
	width:100%;
	left:0;
	right:0;
	bottom:-15px;
	margin:0px auto;
}
.prs_es_pt_box_wrapper li:last-child:after{
	display:none;
}
.prs_es_pt_box_wrapper li:first-child{
	margin-top:40px;
}
.prs_es_pricing_btn_wrapper li a {
    float: left;
    width: 145px;
    height: 45px;
    background: #ff4444;
    color: #ffffff;
    text-align: center;
    line-height: 44px;
	letter-spacing:1px;;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.prs_es_pricing_btn_wrapper li a:hover {
    background: #e31e1e;
    color: #fff;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	text-decoration: none;
}

/**************------- EVENT SINGLE CSS End ---------*******************/
.gc_search_box{
    position: absolute;
    right: 0px;
    top:0;
    padding: 0px;
    width: 100%;
    display: inline-block;
    border-radius: 0px;
    background-color: #ffffff;
    display: none;
    z-index: 1000;
}
.gc_search_box input{
    float: left;
    width: calc(100% - 60px);
    padding: 0px 10px;
    height: 100px;
    border: 1px solid #e1e1e1;
    border-right: 0px;
	font-size:20px;
	color:#000000;
}
.gc_search_box button{
    width: 60px;
    height: 100px;
	color:#000000;
    border: 1px solid #e1e1e1;
    border-left: none;
    background-color: #ffffff;
    text-align: center;
    padding: 0px;
	font-size:24px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.prs_es_faq_btn_wrapper li a{
	background:#ffffff !important;
}
.prs_es_faq_btn_wrapper li a:hover{
	border:1px solid #ffffff !important;
}
#map{
	width:100%; 
	float:left; 
	height:560px;
}
.mobile-logo a svg{
	fill:#ff4444;
	width:50px;
	height:50px;
	float:left;
}
.mobile-logo a span{
	color:#000000;
	font-weight:bold;
	font-size:22px;
	position: relative;
    top: 10px;
    left: 5px;
	text-transform:uppercase;
}
.prs_feature_slider_item_wrapper img{
	-webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
	-webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
}
.prs_animate_btn1  li a{
	text-transform:uppercase !important;
}
/*---------------- st LogIn Start --------------*/
.st_pop_form_wrapper .close {
    position: absolute;
    top: 20px;
    right: 25px;
    z-index: 1;
    font-size: 35px;
}
.st_pop_form_wrapper .modal-dialog{
	max-width:350px;
}
.modal{
	top:20%;
}
.st_pop_form_heading_wrapper h3{
	font-size: 18px;
color: rgba(0,0,0,0.87);
letter-spacing: 1px;
text-align: center;
font-weight:700;
text-transform:uppercase;
}
.st_pop_form_wrapper .modal-content{
	padding:30px;
	float: left;
}
.st_pop_form_heading_wrapper{
	padding-bottom:20px;
}
.st_profile__pass_input_pop{
	margin-top:20px;
}
.st_form_pop_fp{
	text-align:right;
	padding-top:10px;
}
.st_form_pop_fp h3 a{
	font-size: 12px;
color: #ff4444;
letter-spacing: 0;
}
.st_form_pop_login_btn a{
	    width: 100%;
		margin-top:10px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    float: left;
    background: rgba(0,0,0,0.12);
    font-size: 14px;
    font-weight: 500;
    color: rgba(0,0,0,0.38);
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.12);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_form_pop_login_btn a:hover {
    background: #ff4444;
    color: #fff;
    border: 1px solid #ff4444;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_form_pop_or_btn{
	text-align:center;
	margin-top:20px;
	position:relative;
}
.st_form_pop_or_btn h4{
	display:inline-block;
	width:30px;
	height:30px;
	background: #ff4444;
	line-height:30px;
	text-align:center;
	border-radius:4px;
	font-size: 12px;
color: #FFFFFF;
letter-spacing: 0.67px;
	text-transform:uppercase;
	position:relative;
	z-index:1;
}
.st_form_pop_or_btn:after{
	content:'';
	width:100%;
	height:1px;
	position:absolute;
	background: #E0E0E0;
	left:0;
	right:0;
	top:15px;
}
.st_form_pop_facebook_btn a{
	    width: 100%;
		margin-top:20px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    float: left;
    background: #3B57A1;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 1px;
    text-transform: capitalize;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.12);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_form_pop_facebook_btn a:hover {
    background: #1e3e92;
    color: #fff;
    border: 1px solid #1e3e92;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_form_pop_facebook_btn a i{
	    float: left;
    line-height: 40px;
    /* padding-left: 10px; */
    position: relative;
    left: 20px;
	font-size:16px;
}
.st_form_pop_gmail_btn a{
	    width: 100%;
		margin-top:20px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    float: left;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0,0,0,0.54);
    letter-spacing: 1px;
    text-transform: capitalize;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.12);
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_form_pop_gmail_btn a:hover {
    background: #ff4444;
    color: #fff;
    border: 1px solid #ff4444;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_form_pop_gmail_btn a i{
	    float: left;
    line-height: 40px;
    /* padding-left: 10px; */
    position: relative;
    left: 20px;
	font-size:16px;
}
.st_form_pop_signin_btn h4{
	font-size: 14px;
color: rgba(0,0,0,0.54);
letter-spacing: 0.78px;
text-align:center;
padding-top:30px;
text-transform:capitalize;
font-weight:500;
}
.st_form_pop_signin_btn h4 a{
color: #ff4444;
}
.st_form_pop_signin_btn h5{
	font-size: 12px;
color: rgba(0,0,0,0.54);
letter-spacing: 0;
text-align: center;
padding-top:10px;
}
.st_form_pop_signin_btn h5 a{
	text-decoration:none;
}
.st_form_pop_signin_btn h5 a:hover{
	color:#ff4444;
}
.st_pop_form_heading_wrapper p{
	font-size: 12px;
color: rgba(0,0,0,0.87);
letter-spacing: 1px;
text-align: center;
line-height: 16px;
padding-top:25px;
}
.st_pop_form_heading_wrapper_fpass{
	padding-bottom:0;
}
.st_form_pop_fpass_btn a{
	    width: 100%;
		margin-top:30px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    float: left;
    background: #ff4444;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid #ff4444;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_form_pop_fpass_btn a:hover {
    background: transparent;
    color: #ff4444;
    border: 1px solid #ff4444;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_profile_input input{
	width:100%;
	height:40px;
	border: 1px solid rgba(0,0,0,0.38);
	border-radius: 4px;	
	padding-left:20px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_profile_input label {
    color: #ff4444;
    font-size: 12px;
    background: #fff;
    position: relative;
    top: 18px;
    left: 20px;
    font-weight: 600;
    padding: 0px 10px;
}
.st_profile__pass_input input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0,0,0,0.38);
    border-radius: 4px;
    padding-left: 20px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
/*---------------- st LogIn End --------------*/
.movieCard{
	width: 15;
}

.movieCard:hover {
	box-shadow: 7px 6px 13px -1px #00000069;
}

.showTimenDatePick {
	flex-grow: 1;
    width: "100%";
    /* background-color: palette.background.paper */
	
	
}