	/*
  	Flaticon icon font: Flaticon
  	Creation date: 17/04/2018 13:55
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    /* src: url("./Flaticon.svg#Flaticon") format("svg"); */
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-close-envelope:before { content: "\f100"; }
.flaticon-placeholder:before { content: "\f101"; }
.flaticon-call-answer:before { content: "\f102"; }
.flaticon-left-arrow:before { content: "\f103"; }
.flaticon-right-arrow:before { content: "\f104"; }
.flaticon-cart-of-ecommerce:before { content: "\f105"; }
.flaticon-play-button:before { content: "\f106"; }
.flaticon-tool:before { content: "\f107"; }