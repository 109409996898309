/* Common styles of menus */

.dl-menuwrapper {
	width: 100%;
	max-width: 370px;
	float: left;
	position: relative;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	-webkit-perspective-origin: 50% 200%;
	perspective-origin: 50% 200%;
}
.dl-menuwrapper:first-child { margin-right: 100px; }
.dl-menuwrapper button {
	background: #ccc;
	border: none;
	width: 48px;
	height: 45px;
	text-indent: -900em;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	outline: none;
}
.dl-menuwrapper button:hover, .dl-menuwrapper button.dl-active, .dl-menuwrapper ul { background: #aaa; }

.dl-menuwrapper ul {
	padding: 0;
	list-style: none;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	    height: 320px;
    overflow: auto;
}
.dl-menuwrapper li { position: relative; }
.dl-menuwrapper li a {
	display: block;
	padding: 15px 20px;
	font-size: 16px;
	line-height: 20px;
	font-weight: 300;
	color: #fff;
	outline: none;
	text-transform:capitalize;
}
.no-touch .dl-menuwrapper li a:hover { background: rgba(255,248,213,0.1); }
.dl-menuwrapper li.dl-back > a {
	padding-left: 30px;
	background: rgba(0,0,0,0.1);
}
.dl-menuwrapper li.dl-back:after, .dl-menuwrapper li > a:not(:only-child):after {
	position: absolute;
	top: 0;
	line-height: 50px;
	font-family: 'FontAwesome';
	speak: none;
	-webkit-font-smoothing: antialiased;
	content: "\f0da";
}
.dl-menuwrapper li.dl-back:after {
	left: 10px;
	color: #ffffff;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}
.dl-menuwrapper li > a:after {
	right: 10px;
	color: #ffffff;
}
.dl-menuwrapper .dl-menu {
	margin: 27px 0 0 0;
	position: absolute;
	width: 100%;
	opacity: 0;
	pointer-events: none;
	-webkit-transform: translateY(10px);
	transform: translateY(10px);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.dl-menuwrapper .dl-menu.dl-menu-toggle { transition: all 0.3s ease; }
.dl-menuwrapper .dl-menu.dl-menuopen {
	opacity: 1;
	pointer-events: auto;
	-webkit-transform: translateY(0px);
	transform: translateY(0px);
}
/* Hide the inner submenus */
.dl-menuwrapper li .dl-submenu { display: none; }
/* 
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link. 
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menuwrapper .dl-menu.dl-subview li,.dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > a,.dl-menuwrapper .dl-menu.dl-subview li.dl-subview > a { display: none; }
.dl-menuwrapper .dl-menu.dl-subview li.dl-subview,.dl-menuwrapper .dl-menu.dl-subview li.dl-subview .dl-submenu, .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen,.dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,.dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li { display: block; }
/* Dynamically added submenu outside of the menu context */
.dl-menuwrapper > .dl-submenu {
	position: absolute;
	width: 100%;
	top: 50px;
	left: 0;
	margin: 0;
}
/* Animation classes for moving out and in */

.dl-menuwrapper .dl-menu.dl-animate-out-1 {
	-webkit-animation: MenuAnimOut1 0.4s;
	animation: MenuAnimOut1 0.4s;
}
.dl-menuwrapper .dl-menu.dl-animate-out-2 {
	-webkit-animation: MenuAnimOut2 0.3s ease-in-out;
	animation: MenuAnimOut2 0.3s ease-in-out;
}
.dl-menuwrapper .dl-menu.dl-animate-out-3 {
	-webkit-animation: MenuAnimOut3 0.4s ease;
	animation: MenuAnimOut3 0.4s ease;
}
.dl-menuwrapper .dl-menu.dl-animate-out-4 {
	-webkit-animation: MenuAnimOut4 0.4s ease;
	animation: MenuAnimOut4 0.4s ease;
}
.dl-menuwrapper .dl-menu.dl-animate-out-5 {
	-webkit-animation: MenuAnimOut5 0.4s ease;
	animation: MenuAnimOut5 0.4s ease;
}
 @-webkit-keyframes MenuAnimOut1 {
 0% {
}
 50% {
 -webkit-transform: translateZ(-250px) rotateY(30deg);
}
 75% {
 -webkit-transform: translateZ(-372.5px) rotateY(15deg);
 opacity: .5;
}
 100% {
 -webkit-transform: translateZ(-500px) rotateY(0deg);
 opacity: 0;
}
}
 @-webkit-keyframes MenuAnimOut2 {
 0% {
}
 100% {
 -webkit-transform: translateX(-100%);
 opacity: 0;
}
}
 @-webkit-keyframes MenuAnimOut3 {
 0% {
}
 100% {
 -webkit-transform: translateZ(300px);
 opacity: 0;
}
}
 @-webkit-keyframes MenuAnimOut4 {
 0% {
}
 100% {
 -webkit-transform: translateZ(-300px);
 opacity: 0;
}
}
 @-webkit-keyframes MenuAnimOut5 {
 0% {
}
 100% {
 -webkit-transform: translateY(40%);
 opacity: 0;
}
}
 @keyframes MenuAnimOut1 {
 0% {
}
 50% {
 -webkit-transform: translateZ(-250px) rotateY(30deg);
 transform: translateZ(-250px) rotateY(30deg);
}
 75% {
 -webkit-transform: translateZ(-372.5px) rotateY(15deg);
 transform: translateZ(-372.5px) rotateY(15deg);
 opacity: .5;
}
 100% {
 -webkit-transform: translateZ(-500px) rotateY(0deg);
 transform: translateZ(-500px) rotateY(0deg);
 opacity: 0;
}
}
 @keyframes MenuAnimOut2 {
 0% {
}
 100% {
 -webkit-transform: translateX(-100%);
 transform: translateX(-100%);
 opacity: 0;
}
}
 @keyframes MenuAnimOut3 {
 0% {
}
 100% {
 -webkit-transform: translateZ(300px);
 transform: translateZ(300px);
 opacity: 0;
}
}
 @keyframes MenuAnimOut4 {
 0% {
}
 100% {
 -webkit-transform: translateZ(-300px);
 transform: translateZ(-300px);
 opacity: 0;
}
}
 @keyframes MenuAnimOut5 {
 0% {
}
 100% {
 -webkit-transform: translateY(40%);
 transform: translateY(40%);
 opacity: 0;
}
}
.dl-menuwrapper .dl-menu.dl-animate-in-1 {
	-webkit-animation: MenuAnimIn1 0.3s;
	animation: MenuAnimIn1 0.3s;
}
.dl-menuwrapper .dl-menu.dl-animate-in-2 {
	-webkit-animation: MenuAnimIn2 0.3s ease-in-out;
	animation: MenuAnimIn2 0.3s ease-in-out;
}
.dl-menuwrapper .dl-menu.dl-animate-in-3 {
	-webkit-animation: MenuAnimIn3 0.4s ease;
	animation: MenuAnimIn3 0.4s ease;
}
.dl-menuwrapper .dl-menu.dl-animate-in-4 {
	-webkit-animation: MenuAnimIn4 0.4s ease;
	animation: MenuAnimIn4 0.4s ease;
}
.dl-menuwrapper .dl-menu.dl-animate-in-5 {
	-webkit-animation: MenuAnimIn5 0.4s ease;
	animation: MenuAnimIn5 0.4s ease;
}
 @-webkit-keyframes MenuAnimIn1 {
 0% {
 -webkit-transform: translateZ(-500px) rotateY(0deg);
 opacity: 0;
}
 20% {
 -webkit-transform: translateZ(-250px) rotateY(30deg);
 opacity: 0.5;
}
 100% {
 -webkit-transform: translateZ(0px) rotateY(0deg);
 opacity: 1;
}
}
 @-webkit-keyframes MenuAnimIn2 {
 0% {
 -webkit-transform: translateX(-100%);
 opacity: 0;
}
 100% {
 -webkit-transform: translateX(0px);
 opacity: 1;
}
}
 @-webkit-keyframes MenuAnimIn3 {
 0% {
 -webkit-transform: translateZ(300px);
 opacity: 0;
}
 100% {
 -webkit-transform: translateZ(0px);
 opacity: 1;
}
}
 @-webkit-keyframes MenuAnimIn4 {
 0% {
 -webkit-transform: translateZ(-300px);
 opacity: 0;
}
 100% {
 -webkit-transform: translateZ(0px);
 opacity: 1;
}
}
 @-webkit-keyframes MenuAnimIn5 {
 0% {
 -webkit-transform: translateY(40%);
 opacity: 0;
}
 100% {
 -webkit-transform: translateY(0);
 opacity: 1;
}
}
 @keyframes MenuAnimIn1 {
 0% {
 -webkit-transform: translateZ(-500px) rotateY(0deg);
 transform: translateZ(-500px) rotateY(0deg);
 opacity: 0;
}
 20% {
 -webkit-transform: translateZ(-250px) rotateY(30deg);
 transform: translateZ(-250px) rotateY(30deg);
 opacity: 0.5;
}
 100% {
 -webkit-transform: translateZ(0px) rotateY(0deg);
 transform: translateZ(0px) rotateY(0deg);
 opacity: 1;
}
}
 @keyframes MenuAnimIn2 {
 0% {
 -webkit-transform: translateX(-100%);
 transform: translateX(-100%);
 opacity: 0;
}
 100% {
 -webkit-transform: translateX(0px);
 transform: translateX(0px);
 opacity: 1;
}
}
 @keyframes MenuAnimIn3 {
 0% {
 -webkit-transform: translateZ(300px);
 transform: translateZ(300px);
 opacity: 0;
}
 100% {
 -webkit-transform: translateZ(0px);
 transform: translateZ(0px);
 opacity: 1;
}
}
 @keyframes MenuAnimIn4 {
 0% {
 -webkit-transform: translateZ(-300px);
 transform: translateZ(-300px);
 opacity: 0;
}
 100% {
 -webkit-transform: translateZ(0px);
 transform: translateZ(0px);
 opacity: 1;
}
}
 @keyframes MenuAnimIn5 {
 0% {
 -webkit-transform: translateY(40%);
 transform: translateY(40%);
 opacity: 0;
}
 100% {
 -webkit-transform: translateY(0);
 transform: translateY(0);
 opacity: 1;
}
}
.dl-menuwrapper > .dl-submenu.dl-animate-in-1 {
	-webkit-animation: SubMenuAnimIn1 0.4s ease;
	animation: SubMenuAnimIn1 0.4s ease;
}
.dl-menuwrapper > .dl-submenu.dl-animate-in-2 {
	-webkit-animation: SubMenuAnimIn2 0.3s ease-in-out;
	animation: SubMenuAnimIn2 0.3s ease-in-out;
}
.dl-menuwrapper > .dl-submenu.dl-animate-in-3 {
	-webkit-animation: SubMenuAnimIn3 0.4s ease;
	animation: SubMenuAnimIn3 0.4s ease;
}
.dl-menuwrapper > .dl-submenu.dl-animate-in-4 {
	-webkit-animation: SubMenuAnimIn4 0.4s ease;
	animation: SubMenuAnimIn4 0.4s ease;
}
.dl-menuwrapper > .dl-submenu.dl-animate-in-5 {
	-webkit-animation: SubMenuAnimIn5 0.4s ease;
	animation: SubMenuAnimIn5 0.4s ease;
}
 @-webkit-keyframes SubMenuAnimIn1 {
 0% {
 -webkit-transform: translateX(50%);
 opacity: 0;
}
 100% {
 -webkit-transform: translateX(0px);
 opacity: 1;
}
}
 @-webkit-keyframes SubMenuAnimIn2 {
 0% {
 -webkit-transform: translateX(100%);
 opacity: 0;
}
 100% {
 -webkit-transform: translateX(0px);
 opacity: 1;
}
}
 @-webkit-keyframes SubMenuAnimIn3 {
 0% {
 -webkit-transform: translateZ(-300px);
 opacity: 0;
}
 100% {
 -webkit-transform: translateZ(0px);
 opacity: 1;
}
}
 @-webkit-keyframes SubMenuAnimIn4 {
 0% {
 -webkit-transform: translateZ(300px);
 opacity: 0;
}
 100% {
 -webkit-transform: translateZ(0px);
 opacity: 1;
}
}
 @-webkit-keyframes SubMenuAnimIn5 {
 0% {
 -webkit-transform: translateZ(-200px);
 opacity: 0;
}
 100% {
 -webkit-transform: translateZ(0);
 opacity: 1;
}
}
 @keyframes SubMenuAnimIn1 {
 0% {
 -webkit-transform: translateX(50%);
 transform: translateX(50%);
 opacity: 0;
}
 100% {
 -webkit-transform: translateX(0px);
 transform: translateX(0px);
 opacity: 1;
}
}
 @keyframes SubMenuAnimIn2 {
 0% {
 -webkit-transform: translateX(100%);
 transform: translateX(100%);
 opacity: 0;
}
 100% {
 -webkit-transform: translateX(0px);
 transform: translateX(0px);
 opacity: 1;
}
}
 @keyframes SubMenuAnimIn3 {
 0% {
 -webkit-transform: translateZ(-300px);
 transform: translateZ(-300px);
 opacity: 0;
}
 100% {
 -webkit-transform: translateZ(0px);
 transform: translateZ(0px);
 opacity: 1;
}
}
 @keyframes SubMenuAnimIn4 {
 0% {
 -webkit-transform: translateZ(300px);
 transform: translateZ(300px);
 opacity: 0;
}
 100% {
 -webkit-transform: translateZ(0px);
 transform: translateZ(0px);
 opacity: 1;
}
}
 @keyframes SubMenuAnimIn5 {
 0% {
 -webkit-transform: translateZ(-200px);
 transform: translateZ(-200px);
 opacity: 0;
}
 100% {
 -webkit-transform: translateZ(0);
 transform: translateZ(0);
 opacity: 1;
}
}
.dl-menuwrapper > .dl-submenu.dl-animate-out-1 {
	-webkit-animation: SubMenuAnimOut1 0.4s ease;
	animation: SubMenuAnimOut1 0.4s ease;
}
.dl-menuwrapper > .dl-submenu.dl-animate-out-2 {
	-webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
	animation: SubMenuAnimOut2 0.3s ease-in-out;
}
.dl-menuwrapper > .dl-submenu.dl-animate-out-3 {
	-webkit-animation: SubMenuAnimOut3 0.4s ease;
	animation: SubMenuAnimOut3 0.4s ease;
}
.dl-menuwrapper > .dl-submenu.dl-animate-out-4 {
	-webkit-animation: SubMenuAnimOut4 0.4s ease;
	animation: SubMenuAnimOut4 0.4s ease;
}
.dl-menuwrapper > .dl-submenu.dl-animate-out-5 {
	-webkit-animation: SubMenuAnimOut5 0.4s ease;
	animation: SubMenuAnimOut5 0.4s ease;
}
 @-webkit-keyframes SubMenuAnimOut1 {
 0% {
 -webkit-transform: translateX(0%);
 opacity: 1;
}
 100% {
 -webkit-transform: translateX(50%);
 opacity: 0;
}
}
 @-webkit-keyframes SubMenuAnimOut2 {
 0% {
 -webkit-transform: translateX(0%);
 opacity: 1;
}
 100% {
 -webkit-transform: translateX(100%);
 opacity: 0;
}
}
 @-webkit-keyframes SubMenuAnimOut3 {
 0% {
 -webkit-transform: translateZ(0px);
 opacity: 1;
}
 100% {
 -webkit-transform: translateZ(-300px);
 opacity: 0;
}
}
 @-webkit-keyframes SubMenuAnimOut4 {
 0% {
 -webkit-transform: translateZ(0px);
 opacity: 1;
}
 100% {
 -webkit-transform: translateZ(300px);
 opacity: 0;
}
}
 @-webkit-keyframes SubMenuAnimOut5 {
 0% {
 -webkit-transform: translateZ(0);
 opacity: 1;
}
 100% {
 -webkit-transform: translateZ(-200px);
 opacity: 0;
}
}
 @keyframes SubMenuAnimOut1 {
 0% {
 -webkit-transform: translateX(0%);
 transform: translateX(0%);
 opacity: 1;
}
 100% {
 -webkit-transform: translateX(50%);
 transform: translateX(50%);
 opacity: 0;
}
}
 @keyframes SubMenuAnimOut2 {
 0% {
 -webkit-transform: translateX(0%);
 transform: translateX(0%);
 opacity: 1;
}
 100% {
 -webkit-transform: translateX(100%);
 transform: translateX(100%);
 opacity: 0;
}
}
 @keyframes SubMenuAnimOut3 {
 0% {
 -webkit-transform: translateZ(0px);
 transform: translateZ(0px);
 opacity: 1;
}
 100% {
 -webkit-transform: translateZ(-300px);
 transform: translateZ(-300px);
 opacity: 0;
}
}
 @keyframes SubMenuAnimOut4 {
 0% {
 -webkit-transform: translateZ(0px);
 transform: translateZ(0px);
 opacity: 1;
}
 100% {
 -webkit-transform: translateZ(300px);
 transform: translateZ(300px);
 opacity: 0;
}
}
 @keyframes SubMenuAnimOut5 {
 0% {
 -webkit-transform: translateZ(0);
 transform: translateZ(0);
 opacity: 1;
}
 100% {
 -webkit-transform: translateZ(-200px);
 transform: translateZ(-200px);
 opacity: 0;
}
}
/* No JS Fallback */
.no-js .dl-menuwrapper .dl-menu {
	position: relative;
	opacity: 1;
	-webkit-transform: none;
	transform: none;
}
.no-js .dl-menuwrapper li .dl-submenu { display: block; }
.no-js .dl-menuwrapper li.dl-back { display: none; }
.no-js .dl-menuwrapper li > a:not(:only-child) { background: rgba(0,0,0,0.1); }
.no-js .dl-menuwrapper li > a:not(:only-child):after { content: ''; }
/* Colors for demos */

/* Demo 1 */
.demo-1 .dl-menuwrapper button { background: #c62860; }
.demo-1 .dl-menuwrapper button:hover, .demo-1 .dl-menuwrapper button.dl-active, .demo-1 .dl-menuwrapper ul { background: #9e1847; }
/* Demo 2 */
.demo-2 .dl-menuwrapper button { background: #e86814; }
.demo-2 .dl-menuwrapper button:hover, .demo-2 .dl-menuwrapper button.dl-active, .demo-2 .dl-menuwrapper ul { background: #D35400; }
/* Demo 3 */
.demo-3 .dl-menuwrapper button { background: #08cbc4; }
.demo-3 .dl-menuwrapper button:hover, .demo-3 .dl-menuwrapper button.dl-active, .demo-3 .dl-menuwrapper ul { background: #00b4ae; }
/* Demo 4 */
.demo-4 .dl-menuwrapper button { background: #90b912; }
.demo-4 .dl-menuwrapper button:hover, .demo-4 .dl-menuwrapper button.dl-active, .demo-4 .dl-menuwrapper ul { background: #79a002; }
/* Demo 5 */
.demo-5 .dl-menuwrapper button { background: #744783; }
.demo-5 .dl-menuwrapper button:hover, .demo-5 .dl-menuwrapper button.dl-active, .demo-5 .dl-menuwrapper ul { background: #643771; }
