.st_seat_lay_row #c1[type="checkbox"] + label:before {
	content:"1";
}
.st_seat_lay_row #c2[type="checkbox"] + label:before {
	content:"2";
}
.st_seat_lay_row #c3[type="checkbox"] + label:before {
	content:"3";
}
.st_seat_lay_row #c4[type="checkbox"] + label:before {
	content:"4";
}
.st_seat_lay_row #c5[type="checkbox"] + label:before {
	content:"5";
}
.st_seat_lay_row #c6[type="checkbox"] + label:before {
	content:"6";
}
.st_seat_lay_row #c7[type="checkbox"] + label:before {
	content:"7";
}
.st_seat_lay_row #c8[type="checkbox"] + label:before {
	content:"8";
}
.st_seat_lay_row #c9[type="checkbox"] + label:before {
	content:"9";
}
.st_seat_lay_row #c10[type="checkbox"] + label:before {
	content:"10";
}
.st_seat_lay_row #c11[type="checkbox"] + label:before {
	content:"11";
}
.st_seat_lay_row #c12[type="checkbox"] + label:before {
	content:"12";
}
.st_seat_lay_row #c13[type="checkbox"] + label:before {
	content:"13";
}
.st_seat_lay_row #c14[type="checkbox"] + label:before {
	content:"14";
}
.st_seat_lay_row #c15[type="checkbox"] + label:before {
	content:"15";
}
.st_seat_lay_row #c16[type="checkbox"] + label:before {
	content:"16";
}
.st_seat_lay_row #c17[type="checkbox"] + label:before {
	content:"17";
}
.st_seat_lay_row #c18[type="checkbox"] + label:before {
	content:"18";
}
.st_seat_lay_row #c19[type="checkbox"] + label:before {
	content:"19";
}
.st_seat_lay_row #c20[type="checkbox"] + label:before {
	content:"20";
}
.st_seat_lay_row #c21[type="checkbox"] + label:before {
	content:"21";
}
.st_seat_lay_row #c22[type="checkbox"] + label:before {
	content:"22";
}
.st_seat_lay_row #c23[type="checkbox"] + label:before {
	content:"23";
}
.st_seat_lay_row #c24[type="checkbox"] + label:before {
	content:"1";
}
.st_seat_lay_row #c25[type="checkbox"] + label:before {
	content:"2";
}
.st_seat_lay_row #c26[type="checkbox"] + label:before {
	content:"3";
}
.st_seat_lay_row #c27[type="checkbox"] + label:before {
	content:"4";
}
.st_seat_lay_row #c28[type="checkbox"] + label:before {
	content:"5";
}
.st_seat_lay_row #c29[type="checkbox"] + label:before {
	content:"6";
}
.st_seat_lay_row #c30[type="checkbox"] + label:before {
	content:"7";
}
.st_seat_lay_row #c31[type="checkbox"] + label:before {
	content:"8";
}
.st_seat_lay_row #c32[type="checkbox"] + label:before {
	content:"9";
}
.st_seat_lay_row #c33[type="checkbox"] + label:before {
	content:"10";
}
.st_seat_lay_row #c34[type="checkbox"] + label:before {
	content:"11";
}
.st_seat_lay_row #c35[type="checkbox"] + label:before {
	content:"12";
}
.st_seat_lay_row #c36[type="checkbox"] + label:before {
	content:"13";
}
.st_seat_lay_row #c37[type="checkbox"] + label:before {
	content:"14";
}
.st_seat_lay_row #c38[type="checkbox"] + label:before {
	content:"15";
}
.st_seat_lay_row #c39[type="checkbox"] + label:before {
	content:"16";
}
.st_seat_lay_row #c40[type="checkbox"] + label:before {
	content:"17";
}
.st_seat_lay_row #c41[type="checkbox"] + label:before {
	content:"18";
}
.st_seat_lay_row #c42[type="checkbox"] + label:before {
	content:"19";
}
.st_seat_lay_row #c43[type="checkbox"] + label:before {
	content:"20";
}
.st_seat_lay_row #c44[type="checkbox"] + label:before {
	content:"21";
}
.st_seat_lay_row #c45[type="checkbox"] + label:before {
	content:"22";
}
.st_seat_lay_row #c46[type="checkbox"] + label:before {
	content:"23";
}
.st_seat_lay_row #c47[type="checkbox"] + label:before {
	content:"1";
}
.st_seat_lay_row #c48[type="checkbox"] + label:before {
	content:"2";
}
.st_seat_lay_row #c49[type="checkbox"] + label:before {
	content:"3";
}
.st_seat_lay_row #c50[type="checkbox"] + label:before {
	content:"4";
}
.st_seat_lay_row #c51[type="checkbox"] + label:before {
	content:"5";
}
.st_seat_lay_row #c52[type="checkbox"] + label:before {
	content:"6";
}
.st_seat_lay_row #c53[type="checkbox"] + label:before {
	content:"7";
}
.st_seat_lay_row #c54[type="checkbox"] + label:before {
	content:"8";
}
.st_seat_lay_row #c55[type="checkbox"] + label:before {
	content:"9";
}
.st_seat_lay_row #c56[type="checkbox"] + label:before {
	content:"10";
}
.st_seat_lay_row #c57[type="checkbox"] + label:before {
	content:"11";
}
.st_seat_lay_row #c58[type="checkbox"] + label:before {
	content:"12";
}
.st_seat_lay_row #c59[type="checkbox"] + label:before {
	content:"13";
}
.st_seat_lay_row #c60[type="checkbox"] + label:before {
	content:"14";
}
.st_seat_lay_row #c61[type="checkbox"] + label:before {
	content:"15";
}
.st_seat_lay_row #c62[type="checkbox"] + label:before {
	content:"16";
}
.st_seat_lay_row #c63[type="checkbox"] + label:before {
	content:"17";
}
.st_seat_lay_row #c64[type="checkbox"] + label:before {
	content:"18";
}
.st_seat_lay_row #c65[type="checkbox"] + label:before {
	content:"19";
}
.st_seat_lay_row #c66[type="checkbox"] + label:before {
	content:"20";
}
.st_seat_lay_row #c67[type="checkbox"] + label:before {
	content:"21";
}
.st_seat_lay_row #c68[type="checkbox"] + label:before {
	content:"22";
}
.st_seat_lay_row #c69[type="checkbox"] + label:before {
	content:"23";
}
.st_seat_lay_row #c70[type="checkbox"] + label:before {
	content:"1";
}
.st_seat_lay_row #c71[type="checkbox"] + label:before {
	content:"2";
}
.st_seat_lay_row #c72[type="checkbox"] + label:before {
	content:"3";
}
.st_seat_lay_row #c73[type="checkbox"] + label:before {
	content:"4";
}
.st_seat_lay_row #c74[type="checkbox"] + label:before {
	content:"5";
}
.st_seat_lay_row #c75[type="checkbox"] + label:before {
	content:"6";
}
.st_seat_lay_row #c76[type="checkbox"] + label:before {
	content:"7";
}
.st_seat_lay_row #c77[type="checkbox"] + label:before {
	content:"8";
}
.st_seat_lay_row #c78[type="checkbox"] + label:before {
	content:"9";
}
.st_seat_lay_row #c79[type="checkbox"] + label:before {
	content:"10";
}
.st_seat_lay_row #c80[type="checkbox"] + label:before {
	content:"11";
}
.st_seat_lay_row #c81[type="checkbox"] + label:before {
	content:"12";
}
.st_seat_lay_row #c82[type="checkbox"] + label:before {
	content:"13";
}
.st_seat_lay_row #c83[type="checkbox"] + label:before {
	content:"14";
}
.st_seat_lay_row #c84[type="checkbox"] + label:before {
	content:"15";
}
.st_seat_lay_row #c85[type="checkbox"] + label:before {
	content:"16";
}
.st_seat_lay_row #c86[type="checkbox"] + label:before {
	content:"17";
}
.st_seat_lay_row #c87[type="checkbox"] + label:before {
	content:"18";
}
.st_seat_lay_row #c88[type="checkbox"] + label:before {
	content:"19";
}
.st_seat_lay_row #c89[type="checkbox"] + label:before {
	content:"20";
}
.st_seat_lay_row #c90[type="checkbox"] + label:before {
	content:"21";
}
.st_seat_lay_row #c91[type="checkbox"] + label:before {
	content:"22";
}
.st_seat_lay_row #c92[type="checkbox"] + label:before {
	content:"23";
}
.st_seat_lay_row #A1[type="checkbox"] + label:before {
	content:"A1";
}
.st_seat_lay_row #A2[type="checkbox"] + label:before {
	content:"A2";
}
.st_seat_lay_row #A3[type="checkbox"] + label:before {
	content:"A3";
}
.st_seat_lay_row #AA1[type="checkbox"] + label:before {
	content:"AA1";
}
.st_seat_lay_row #AA2[type="checkbox"] + label:before {
	content:"AA2";
}
.st_seat_lay_row #D1[type="checkbox"] + label:before {
	content:"D1";
}
.st_seat_lay_row #D2[type="checkbox"] + label:before {
	content:"D2";
}
.st_seat_lay_row #D3[type="checkbox"] + label:before {
	content:"D3";
}
.st_seat_lay_row #D4[type="checkbox"] + label:before {
	content:"D4";
}
.st_seat_lay_row #DD1[type="checkbox"] + label:before {
	content:"DD1";
}
.st_seat_lay_row #DD2[type="checkbox"] + label:before {
	content:"DD2";
}
.st_seat_lay_row #DD3[type="checkbox"] + label:before {
	content:"DD3";
}